import { QpSingleCheckBoxDetail } from "@/components/features/quickPrompt/quickPrompt.type";
import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { ReqQpPropertyEdit } from "../../qpEditForm.type";
import QpRequiredCheckbox from "./QpRequiredCheckbox";

interface Props {
  property: ReqQpPropertyEdit;
  allProperties: ReqQpPropertyEdit[];
  detail: QpSingleCheckBoxDetail;
  onChange: (data: QpSingleCheckBoxDetail) => void;
}

function QpSingleCheckboxSettingFields({
  detail,
  property,
  allProperties,
  onChange,
}: Props) {

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    onChange({
      ...detail,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  return (
    <>
      <QpRequiredCheckbox
        property={property}
        allProperties={allProperties}
        checked={detail.isRequired || false}
        onChange={handleInputChange}
      />
      <Box/>
      <FormControlLabel
        control={
          <Checkbox
            checked={detail.defaultValue || false}
            onChange={handleInputChange}
            name="defaultValue"
          />
        }
        label="初期状態でチェックを入れる"
      />
      <TextField
        label="ヘルプテキスト"
        name="helpText"
        value={detail.helpText || ''}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="チェック時のプロンプト"
        name="prompt"
        value={detail.prompt || ""}
        onChange={handleInputChange}
        multiline
        rows={2}
        fullWidth
        placeholder="未入力時は項目名がそのままプロンプトとして使われます。"
        margin="normal"
        />
    </>
  );
}

export default QpSingleCheckboxSettingFields;
