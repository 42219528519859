import React from 'react';
import * as Icons from '@mui/icons-material';

interface DynamicIconProps {
  iconName?: string;
}

export const DynamicIcon: React.FC<DynamicIconProps> = ({ iconName }) => {
  const IconComponent =
    iconName && iconName in Icons ? Icons[iconName as keyof typeof Icons] : Icons.InfoOutlined;
  return <IconComponent />;
};

export default DynamicIcon;

