import { QpInputPropertyDetailBase } from "@/components/features/quickPrompt/quickPrompt.type";
import { TextField } from "@mui/material";
import { ReqQpPropertyEdit } from "../../qpEditForm.type";
import QpRequiredCheckbox from "./QpRequiredCheckbox";

interface Props {
  property: ReqQpPropertyEdit;
  allProperties: ReqQpPropertyEdit[];
  detail: QpInputPropertyDetailBase;
  onChange: (data: QpInputPropertyDetailBase) => void;
}

function QpInputPropertySettingFieldsBase({
  property,
  allProperties,
  detail,
  onChange,
}: Props) {

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    onChange({
      ...detail,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  return (
    <>
      <QpRequiredCheckbox
        property={property}
        allProperties={allProperties}
        checked={detail.isRequired || false}
        onChange={handleInputChange}
      />
      <TextField
        label="ヘルプテキスト"
        name="helpText"
        value={detail.helpText || ''}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
    </>
  );
}

export default QpInputPropertySettingFieldsBase;
