import { FocusEvent } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  ariaDescribedByIds,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  WidgetProps,
} from '@rjsf/utils';
import { useTranslation } from 'react-i18next';

// 下記を参考にした
// https://github.com/rjsf-team/react-jsonschema-form/blob/main/packages/mui/src/CheckboxWidget/CheckboxWidget.tsx

export default function ToggleAdvancedSettings<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: WidgetProps<T, S, F>) {
  const {
    id,
    value,
    disabled,
    readonly,
    autofocus,
    onChange,
    onBlur,
    onFocus,
  } = props;
  return (
    <ToggleAdvancedSettingsPresenter
      id={id}
      value={value}
      disabled={disabled}
      readonly={readonly}
      autofocus={autofocus}
      ariaDescribedBy={ariaDescribedByIds<T>(id)}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
}

interface ToggleAdvancedSettingsPresenterProps {
  id: string;
  value: any;
  disabled?: boolean;
  readonly?: boolean;
  autofocus?: boolean;
  ariaDescribedBy?: string;
  onChange?: (checked: boolean) => void;
  onBlur?: (id: string, value: any) => void;
  onFocus?: (id: string, value: any) => void;

}

export function  ToggleAdvancedSettingsPresenter({
  id,
  value,
  disabled,
  readonly,
  autofocus,
  ariaDescribedBy,
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
}: ToggleAdvancedSettingsPresenterProps) {
  const { t } = useTranslation();
  const _onChange = (_: any, checked: boolean) => onChange(checked);
  const _onBlur = ({ target }: FocusEvent<HTMLButtonElement>) => onBlur(id, target && target.value);
  const _onFocus = ({ target }: FocusEvent<HTMLButtonElement>) => onFocus(id, target && target.value);

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            id={id}
            name={id}
            checked={typeof value === 'undefined' ? false : Boolean(value)}
            disabled={disabled || readonly}
            autoFocus={autofocus}
            onChange={_onChange}
            onBlur={_onBlur}
            onFocus={_onFocus}
            aria-describedby={ariaDescribedBy}
          />
        }
        label={t("quickPrompt:form.toggleAdvancedConfig")}
      />
    </>
  );
}
