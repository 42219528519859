import React from 'react';
import { DndContext, closestCenter, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { ReqQpPropertyEdit } from '../qpEditForm.type';
import QpEditStep2ItemRow from './QpEditStep2ItemRow';
import { QpPropertyType } from '../../quickPrompt/quickPrompt.type';
import { isAdvanceSettingProperty } from '../qpEditForm.utils';

interface Props {
  properties: ReqQpPropertyEdit[];
  setProperties: React.Dispatch<React.SetStateAction<ReqQpPropertyEdit[]>>;
}

function QpEditStep2ItemListContainer({ properties, setProperties }: Props) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = properties.findIndex((item) => item.id === active.id);
      const newIndex = properties.findIndex((item) => item.id === over.id);
      setProperties((items) => {
        const newItems = arrayMove(items, oldIndex, newIndex);

        // もしも詳細設定より下に配置されたitemにisRequired=trueのものがあったら、
        // isRequired=Falseに変える
        for (let i = 0; i < newItems.length; i++) {
          const item = newItems[i];
          const isAdvanceSetting = isAdvanceSettingProperty(
            item,
            newItems,
          );

          if (isAdvanceSetting) {
            if (item.type == QpPropertyType.TEXT_FIELD && item.detail.textField) {
              item.detail.textField.isRequired = false;
            }
            if (item.type == QpPropertyType.TEXT_AREA && item.detail.textArea) {
              item.detail.textArea.isRequired = false;
            }
            if (item.type == QpPropertyType.CHECK_BOX && item.detail.checkBox) {
              item.detail.checkBox.isRequired = false;
            }
            if (item.type == QpPropertyType.RADIO_BUTTON && item.detail.radioButton) {
              item.detail.radioButton.isRequired = false;
            }
            if (item.type == QpPropertyType.SELECT_BOX && item.detail.selectBox) {
              item.detail.selectBox.isRequired = false;
            }
            if (item.type == QpPropertyType.SINGLE_CHECK_BOX && item.detail.singleCheckBox) {
              item.detail.singleCheckBox.isRequired = false;
            }
            if (item.type == QpPropertyType.TEXT_AREA_WITH_TOOLBAR && item.detail.textAreaWithToolbar) {
              item.detail.textAreaWithToolbar.isRequired = false;
            }
          }
        }

        return newItems;
      });
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={properties.map((item) => item.id)} strategy={verticalListSortingStrategy}>
        {properties.map((property) => (
          <QpEditStep2ItemRow
            key={property.id}
            property={property}
            allProperties={properties}
            onDelete={() => {
              setProperties((prev) => prev.filter((item) => item.id !== property.id));
            }}
            onUpdate={(updatedProperty) => {
              setProperties((prev) =>
                prev.map((item) => (item.id === updatedProperty.id ? updatedProperty : item))
              );
            }}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
}

export default QpEditStep2ItemListContainer;
