import { QpTextAreaDetail } from "@/components/features/quickPrompt/quickPrompt.type";
// import { TextField } from "@mui/material";
import QpTextFieldSettingFields from "./QpTextFieldSettingFields";
import { ReqQpPropertyEdit } from "../../qpEditForm.type";

interface Props {
  property: ReqQpPropertyEdit;
  allProperties: ReqQpPropertyEdit[];
  detail: QpTextAreaDetail;
  onChange: (data: QpTextAreaDetail) => void;
}

function QpTextAreaSettingFields({
  property,
  allProperties,
  detail,
  onChange,
}: Props) {

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value, checked, type } = e.target;
  //   onChange({
  //     ...detail,
  //     [name]: type === 'checkbox' ? checked : value,
  //   });
  // };

  return (
    <>
      <QpTextFieldSettingFields
        property={property}
        allProperties={allProperties}
        detail={detail}
        onChange={(baseData) => onChange({
          ...detail,
          ...baseData,
        })}
        inputRows={3}
      />
      {/* <TextField
        label="行数"
        name="rows"
        type="number"
        value={detail.rows || 5}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      /> */}
    </>
  );
}

export default QpTextAreaSettingFields;
