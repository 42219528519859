import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { QpManagement, QuickPromptSetWithQpList, QuickPromptWithSetAndVersion, QuickPromptWithVersion } from './quickPrompt.type';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import { showUnknownErrorModal } from '../generic/errorModal.slice';
import { AxiosError } from 'axios';
import { RootState } from '@/store';

interface QuickPromptState {
  quickPromptSets: QuickPromptSetWithQpList[];
  bookmarkedQuickPromptIds: string[];
  qpManagement: QpManagement;
}

const initialState: QuickPromptState = {
  quickPromptSets: [],
  bookmarkedQuickPromptIds: [],
  qpManagement: {
    hideSetIds: [],
    hideQpIds: [],
    orderedSetIds: [],
    orderedQpIds: [],
  },
};

export const fetchQuickPromptSetsIfNeeded = createAsyncThunk(
  'quickPrompt/fetchQuickPromptSetsIfNeeded',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const { quickPromptSets } = state.quickPrompt;

    if (quickPromptSets.length > 0) {
      return; // データが既に存在する場合、何もしない
    }

    try {
      const response = await apiAxios.get<QuickPromptSetWithQpList[]>('/quick-prompt-sets');
      dispatch(setQuickPromptSets(response.data));
    } catch (error) {
      captureException(error);
      console.error('Failed to fetch quickPromptSets:', error);
      throw error;
    }
  }
);

export const updateQuickPromptSet = createAsyncThunk(
  'quickPrompt/updateQuickPromptSet',
  async (params: { id: string; name: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiAxios.put(`/quick-prompt-sets/${params.id}`, {
        name: params.name,
      });
      return response.data as QuickPromptSetWithQpList;
    } catch (err) {
      captureException(err);
      if (err instanceof AxiosError) {
        dispatch(showUnknownErrorModal());
        return rejectWithValue(err.message);
      }
      dispatch(showUnknownErrorModal());
      return rejectWithValue('不明なエラーが発生しました');
    }
  }
);

export const addBookmark = createAsyncThunk(
  'quickPrompt/addBookmark',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      setTimeout(() => apiAxios.post(`/quick-prompts/${id}/bookmark`), 1);
      return id;
    } catch (err) {
      captureException(err);
      if (err instanceof AxiosError) {
        dispatch(showUnknownErrorModal());
        return rejectWithValue(err.message);
      }
      dispatch(showUnknownErrorModal());
      return rejectWithValue('不明なエラーが発生しました');
    }
  }
);

export const removeBookmark = createAsyncThunk(
  'quickPrompt/removeBookmark',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      setTimeout(() => apiAxios.delete(`/quick-prompts/${id}/bookmark`), 1);
      return id;
    } catch (err) {
      captureException(err);
      if (err instanceof AxiosError) {
        dispatch(showUnknownErrorModal());
        return rejectWithValue(err.message);
      }
      dispatch(showUnknownErrorModal());
      return rejectWithValue('不明なエラーが発生しました');
    }
  }
);

export const fetchQpManagement = createAsyncThunk(
  'quickPrompt/fetchQpManagement',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await apiAxios.get<QpManagement>('/ui/qp-managements');
      return response.data;
    } catch (err) {
      captureException(err);
      if (err instanceof AxiosError) {
        dispatch(showUnknownErrorModal());
        return rejectWithValue(err.message);
      }
      dispatch(showUnknownErrorModal());
      return rejectWithValue('不明なエラーが発生しました');
    }
  }
);

export const updateQpManagement = createAsyncThunk(
  'quickPrompt/updateQpManagement',
  async (qpManagement: QpManagement, { rejectWithValue, dispatch }) => {
    try {
      setTimeout(() => apiAxios.put('/ui/qp-managements', qpManagement), 1);
      return qpManagement;
    } catch (err) {
      captureException(err);
      if (err instanceof AxiosError) {
        dispatch(showUnknownErrorModal());
        return rejectWithValue(err.message);
      }
      dispatch(showUnknownErrorModal());
      return rejectWithValue('不明なエラーが発生しました');
    }
  }
);

export const deleteQuickPrompt = createAsyncThunk(
  'quickPrompt/deleteQuickPrompt',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      await apiAxios.delete(`/quick-prompts/${id}`);
      return id;
    } catch (err) {
      captureException(err);
      if (err instanceof AxiosError) {
        dispatch(showUnknownErrorModal());
        return rejectWithValue(err.message);
      }
      dispatch(showUnknownErrorModal());
      return rejectWithValue('不明なエラーが発生しました');
    }
  }
);

export const deleteQuickPromptSet = createAsyncThunk(
  'quickPrompt/deleteQuickPromptSet',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      await apiAxios.delete(`/quick-prompt-sets/${id}`);
      return id;
    } catch (err) {
      captureException(err);
      if (err instanceof AxiosError) {
        dispatch(showUnknownErrorModal());
        return rejectWithValue(err.message);
      }
      dispatch(showUnknownErrorModal());
      return rejectWithValue('不明なエラーが発生しました');
    }
  }
);

const handleQuickPrompt = (
  state: QuickPromptState,
  quickPrompt: QuickPromptWithVersion | QuickPromptWithSetAndVersion,
  isWithSet: boolean = false
) => {
  const setId = quickPrompt.quickPromptSetId;
  const setIndex = state.quickPromptSets.findIndex(set => set.id === setId);

  if (setIndex !== -1) {
    // 該当するセットが存在する場合は、そのセットにQPを追加するか、該当QPを差し替える
    const index = state.quickPromptSets[setIndex].quickPrompts.findIndex(qp => qp.id === quickPrompt.id);
    if (index !== -1) {
      state.quickPromptSets[setIndex].quickPrompts[index] = quickPrompt;
    } else {
      state.quickPromptSets[setIndex].quickPrompts.unshift(quickPrompt);
    }
  } else if (isWithSet && 'quickPromptSet' in quickPrompt) {
    // 該当するセットが存在せず、かつセット情報が付与されている場合は新しいセットを追加する
    state.quickPromptSets.unshift({
      ...quickPrompt.quickPromptSet,
      quickPrompts: [quickPrompt],
    });
  }

  // 他のセットから同じIDのクイックプロンプトを削除 (移動されたケースが該当)
  state.quickPromptSets.forEach((set) => {
    if (set.id === setId) return;
    set.quickPrompts = set.quickPrompts.filter(qp => qp.id !== quickPrompt.id);
  });
};

export const quickPromptSlice = createSlice({
  name: 'quickPrompt',
  initialState,
  reducers: {
    setQuickPromptSets: (state, action: PayloadAction<QuickPromptSetWithQpList[]>) => {
      state.quickPromptSets = action.payload;
    },
    createQuickPrompt: (state, action: PayloadAction<QuickPromptWithVersion>) => {
      handleQuickPrompt(state, action.payload);
    },
    createQuickPromptWithSet: (state, action: PayloadAction<QuickPromptWithSetAndVersion>) => {
      handleQuickPrompt(state, action.payload, true);
    },
    updateQuickPrompt: (state, action: PayloadAction<QuickPromptWithVersion>) => {
      handleQuickPrompt(state, action.payload);
    },
    updateQuickPromptWithSet: (state, action: PayloadAction<QuickPromptWithSetAndVersion>) => {
      handleQuickPrompt(state, action.payload, true);
    },
    setBookmarkedQuickPromptIds: (state, action: PayloadAction<string[]>) => {
      state.bookmarkedQuickPromptIds = action.payload;
    },
    setQpManagement: (state, action: PayloadAction<QpManagement>) => {
      state.qpManagement = action.payload;
    },
    setIsSharedForQpSet: (state, action: PayloadAction<{ id: string; isShared: boolean }>) => {
      const { id, isShared } = action.payload;
      const setIndex = state.quickPromptSets.findIndex(set => set.id === id);
      if (setIndex !== -1) {
        state.quickPromptSets[setIndex].isShared = isShared;
      }
    },
    setIsSharedForQp: (state, action: PayloadAction<{ id: string; isShared: boolean }>) => {
      const { id, isShared } = action.payload;
      state.quickPromptSets.forEach(set => {
        const index = set.quickPrompts.findIndex(qp => qp.id === id);
        if (index !== -1) {
          set.quickPrompts[index].isShared = isShared;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateQuickPromptSet.fulfilled, (state, action) => {
      const updatedSet = action.payload;
      const index = state.quickPromptSets.findIndex(set => set.id === updatedSet.id);
      if (index !== -1) {
        state.quickPromptSets[index] = updatedSet;
      }
    });
    builder.addCase(addBookmark.fulfilled, (state, action) => {
      state.bookmarkedQuickPromptIds.unshift(action.payload);
    });
    builder.addCase(removeBookmark.fulfilled, (state, action) => {
      state.bookmarkedQuickPromptIds = state.bookmarkedQuickPromptIds.filter((id) => id !== action.payload);
    });
    builder.addCase(fetchQpManagement.fulfilled, (state, action) => {
      state.qpManagement = action.payload;
    });
    builder.addCase(updateQpManagement.fulfilled, (state, action) => {
      state.qpManagement = action.payload;
    });
    builder.addCase(deleteQuickPrompt.fulfilled, (state, action) => {
      const idToDelete = action.payload;
      state.quickPromptSets.forEach(set => {
        set.quickPrompts = set.quickPrompts.filter(qp => qp.id !== idToDelete);
      });
    });
    builder.addCase(deleteQuickPromptSet.fulfilled, (state, action) => {
      const setIdToDelete = action.payload;
      state.quickPromptSets = state.quickPromptSets.filter(set => set.id !== setIdToDelete);
    });
  },
});

export const {
  setQuickPromptSets,
  createQuickPrompt,
  createQuickPromptWithSet,
  updateQuickPrompt,
  updateQuickPromptWithSet,
  setBookmarkedQuickPromptIds,
  setQpManagement,
  setIsSharedForQpSet,
  setIsSharedForQp,
} = quickPromptSlice.actions;

export default quickPromptSlice.reducer;
