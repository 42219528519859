import QpEditContainer from "@/components/features/quickPromptEdit/containers/QpEditContainer";

const QuickPromptsNewPage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const copyFromId = queryParams.get('copyFrom');

  if (copyFromId) {
    return <QpEditContainer
      quickPromptId={copyFromId}
      isCopyMode={true}
    />;
  }

  return <QpEditContainer/>;
};

export default QuickPromptsNewPage;

