import { QpTextAreaWithToolbarDetail } from "@/components/features/quickPrompt/quickPrompt.type";
import QpTextAreaSettingFields from "./QpTextAreaSettingFields";
import { ReqQpPropertyEdit } from "../../qpEditForm.type";

interface Props {
  property: ReqQpPropertyEdit;
  allProperties: ReqQpPropertyEdit[];
  detail: QpTextAreaWithToolbarDetail;
  onChange: (data: QpTextAreaWithToolbarDetail) => void;
}

function QpTextAreaWithToolbarSettingFields({
  property,
  allProperties,
  detail,
  onChange,
}: Props) {

  return (
    <>
      <QpTextAreaSettingFields
        property={property}
        allProperties={allProperties}
        detail={detail}
        onChange={onChange}
      />
    </>
  );
}

export default QpTextAreaWithToolbarSettingFields;
