import { Fade, Tooltip, tooltipClasses, styled, TooltipProps } from '@mui/material';

const ArrowTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    arrow
    TransitionComponent={Fade}
    classes={{ popper: className }}
    {...props}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.light,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 13,
    padding: 10,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.secondary.light,
  },
}));


export default ArrowTooltip;
