import axios from 'axios';
import { FileUpload } from './file.type';
import apiAxios from '@/lib/axios';
import { FileAnalysisFailureReason, FileAnalysisStatus, FileType, MB, MimeTypeToName, UploadableAudioMaxMb, UploadableAudioMimeTypes, UploadableDocumentMaxMb, UploadableDocumentMimeTypes, UploadableImageMaxMb, UploadableImageMimeTypes, UploadableNotTextMaxMbForStarter, UploadableTxtMaxMb, UploadableTxtMimeTypes, UploadableVideoMaxMb, UploadableVideoMimeTypes } from './file.constant';
import { LoginUserMembership } from '../auth/auth.type';
import { AIModelConst, DEFAULT_AI_MODEL_ID } from '../aiModel/aiModel.constant';
import { getAIModelConsts } from '../aiModel/aiModel.utils';
import * as pdfjsLib from 'pdfjs-dist';
import { captureException } from '@sentry/react';
import i18next from 'i18next';

// ワーカーのソースを設定
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.mjs`;


export class FileUploadError extends Error {
  retryable: boolean;

  constructor(message: string, retryable: boolean = false) {
    super(message);
    this.name = 'FileUploadError';
    this.retryable = retryable;
  }
}

export const upload = async (
  file: File,
  onProgress: (progress: string) => void,
): Promise<FileUpload> => {
  if (isGcsUploadType(file)) {
    return uploadResumable(file, onProgress);
  }
  return uploadInstantly(file, onProgress);
}

export const uploadInstantly = async (
  file: File,
  onProgress: (progress: string) => void,
): Promise<FileUpload> => {
  const formData = new FormData();
  formData.append('file', file);

  const res = await apiAxios.post<FileUpload>('/files/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      if (progressEvent.total) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // t:ファイルをアップロードしています。(進行率: {{percentCompleted}}%)
        onProgress(i18next.t('file:uploadingFile', { percentCompleted }));
      }
    },
  });
  return res.data;
}

export const uploadResumable = async (
  file: File,
  onProgress: (progress: string) => void,
): Promise<FileUpload> => {
  const fileType = convertMimeTypeToFileType(file.type);

  // アップロードURLを準備
  // t:(1/3) ファイルアップロードの準備をしています。
  onProgress(i18next.t('file:preparingFileUpload'));
  const res1 = await apiAxios.post<FileUpload>(
    '/files/upload/initiate',
    {
      filename: file.name,
      mimetype: file.type,
      filesize: file.size,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const uploadUrl = res1.data.uploadUrl;
  if (!uploadUrl) {
    // t:ファイルアップロードの準備に失敗しました。
    throw new FileUploadError(i18next.t('file:error.failedToPrepareFileUpload'), true);
  }

  // アップロード
  const res2 = await axios.put(uploadUrl, file, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    onUploadProgress: (progressEvent) => {
      if (progressEvent.total) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // t:(2/3) ファイルをアップロードしています。(進行率: {{percentCompleted}}%)
        onProgress(i18next.t('file:uploadingFileProgress', { percentCompleted }));
      }
    },
    validateStatus: (status) => {
      return status < 400; // 400未満のステータスを許容する
    },
  });

  if (!res2.status || (res2.status !== 200 && res2.status !== 201)) {
    const errorDetails = await res2.data;
    console.debug(`Failed to upload file: ${errorDetails}`);
    // t:ファイルアップロードに失敗しました。
    throw new FileUploadError(i18next.t('file:error.failedToUploadFile'), true);
  }

  // 解析開始
  // t:(3/3) ファイルの内容を解析中です。
  onProgress(i18next.t('file:analyzingFileContent'));
  const res3 = await apiAxios.post<FileUpload>(`/files/${res1.data.id}/analysis`);
  if (res3.status !== 200) {
    // t:ファイルの解析に失敗しました。
    throw new FileUploadError(i18next.t('file:error.failedToAnalyzeFile'), true);
  }

  // 状態のポーリング
  const interval = 1000;
  const maxAttempts = 60 * 3;  // 3分まで
  let attempts = 0;
  let res4;
  do {
    res4 = await apiAxios.get<FileUpload>(`/files/${res1.data.id}`);
    attempts++;
    await new Promise((resolve) => setTimeout(resolve, interval));
  } while (
    (
      res4.data.analysisStatus != FileAnalysisStatus.DONE &&
      res4.data.analysisStatus != FileAnalysisStatus.FAILED
    ) && attempts < maxAttempts
  );

  const fileUpload = res4.data;

  if (fileUpload.analysisStatus === FileAnalysisStatus.FAILED) {
    if (fileUpload.analysisFailureReason == FileAnalysisFailureReason.DURATION_LIMIT_EXCEEDED) {
      if (fileType == FileType.VIDEO) {
        // t:動画の長さが長すぎます。(1.0時間まで)
        throw new FileUploadError(i18next.t('file:error.videoLengthExceeded'));
      } else {
        // t:音声の長さが長すぎます。(8.5時間まで)
        throw new FileUploadError(i18next.t('file:error.audioLengthExceeded'));
      }
    }
    if (fileUpload.analysisFailureReason == FileAnalysisFailureReason.PAGE_NUM_LIMIT_EXCEEDED) {
      // t:ページ数が多すぎます。(3000ページまで)
      throw new FileUploadError(i18next.t('file:error.pageNumberExceeded'));
    }
  }
  if (fileUpload.analysisStatus == FileAnalysisStatus.PENDING) {
    // t:ファイルの解析が時間切れになりました。
    throw new FileUploadError(i18next.t('file:error.fileAnalysisTimeout'), true);
  }

  return res4.data;
}

export const isGcsUploadType = (file: File): boolean => {
  return [
    ...UploadableVideoMimeTypes,
    ...UploadableAudioMimeTypes,
    ...UploadableDocumentMimeTypes,
  ].includes(file.type);
}

export const convertMimeTypeToName = (mimeType: string) => {
  if (Object.prototype.hasOwnProperty.call(MimeTypeToName, mimeType)) {
    return MimeTypeToName[mimeType as keyof typeof MimeTypeToName];
  } else {
    // t:その他
    return i18next.t('file:fileType.other');
  }
}

export const convertMimeTypeToFileType = (mimeType: string) : FileType | null => {
  if (UploadableImageMimeTypes.includes(mimeType)) {
    return FileType.IMAGE;
  } else if (UploadableAudioMimeTypes.includes(mimeType)) {
    return FileType.AUDIO;
  } else if (UploadableVideoMimeTypes.includes(mimeType)) {
    return FileType.VIDEO;
  } else if (UploadableDocumentMimeTypes.includes(mimeType)) {
    return FileType.DOCUMENT;
  } else if (UploadableTxtMimeTypes.includes(mimeType)) {
    return FileType.TEXT;
  } else {
    return null;
  }
}

export const getLabelForFileType = (fileType: FileType) : string => {
  switch (fileType) {
    case FileType.IMAGE:
      // t:画像
      return i18next.t('file:fileType.image');
    case FileType.AUDIO:
      // t:音声
      return i18next.t('file:fileType.audio');
    case FileType.VIDEO:
      // t:動画
      return i18next.t('file:fileType.video');
    case FileType.DOCUMENT:
      // t:文書
      return i18next.t('file:fileType.document');
    case FileType.TEXT:
      // t:テキスト
      return i18next.t('file:fileType.text');
  }
  // t:その他
  return i18next.t('file:fileType.other');
}

export const getTypeLabelForMimeType = (mimeType: string) : string => {
  const fileType = convertMimeTypeToFileType(mimeType);
  if (fileType) {
    return getLabelForFileType(fileType);
  }
  // t:その他
  return i18next.t('file:fileType.other');
}

export const checkUploadFile = async (file: File | undefined, isStarter: boolean) : Promise<string | undefined> => {
  console.debug('Checking file:', file);

  if (!file) {
    return;
  }

  const mimeType = file.type;
  const fileSizeMb = file.size / MB;

  const fileType = convertMimeTypeToFileType(mimeType);
  if (!fileType) {
    const mimeTypeGroups = {
      imageTypes: UploadableImageMimeTypes,
      audioTypes: UploadableAudioMimeTypes,
      videoTypes: UploadableVideoMimeTypes,
      documentTypes: UploadableDocumentMimeTypes,
      textTypes: UploadableTxtMimeTypes
    };

    const formattedTypes = Object.fromEntries(
      Object.entries(mimeTypeGroups).map(([key, types]) => [
        key,
        Array.from(new Set(types.map(convertMimeTypeToName))).join(', ')
      ])
    );

    return (
      // t:無効なファイルタイプです。
      //   アップロード可能なファイルタイプは以下の通りです。
      //   画像: {{imageTypes}}
      //   オーディオ: {{audioTypes}}
      //   ドキュメント: {{documentTypes}}
      //   テキスト: {{textTypes}}
      i18next.t("file:error.invalidFileType", formattedTypes)
    );
  }

  const maxFileSize = getUploadableMaxMbFor(fileType, isStarter);
  if (fileSizeMb > maxFileSize) {
    if (isStarter) {
      return (
        // t:Starterプランでは、ファイルサイズが下記のように制限されます。
        //   サイズを調整いただくか、プランのアップグレードをご検討ください。
        //
        //   テキスト: {{txtMax}}MBまで
        //   テキスト以外: {{notTextMax}}MBまで
        //   (選択されたファイル: {{size}}MB)
        //
        //   ※ 有料プランでの制限は下記のとおりです。
        //    テキスト: {{txtMax}}MBまで
        //    画像: {{imgMax}}MBまで
        //    音声: {{audioMax}}MBまで
        //    動画: {{videoMax}}MBまで
        //    ドキュメント: {{docMax}}MBま
        i18next.t('file:error.starterPlanFileSizeLimit',
                  {
                    txtMax: UploadableTxtMaxMb,
                    notTextMax: UploadableNotTextMaxMbForStarter,
                    size: maxFileSize,
                    imgMax: UploadableImageMaxMb,
                    audioMax: UploadableAudioMaxMb,
                    videoMax: UploadableVideoMaxMb,
                    docMax: UploadableDocumentMaxMb
                  })
      )
    }
    return (
      // t:ファイルサイズが大きすぎます。
      //   アップロード可能な最大ファイルサイズは以下の通りです。
      //
      //   画像: {{imageMax}}MB
      //   音声: {{audioMax}}MB
      //   動画: {{videoMax}}MBまで
      //   ドキュメント: {{documentMax}}MB
      //   テキスト: {{textMax}}MB
      //
      //   (選択ファイルのサイズ: {{size}}MB)
      i18next.t(
        'file:error.fileSizeTooLarge',
        {
          imageMax: UploadableImageMaxMb,
          audioMax: UploadableAudioMaxMb,
          videoMax: UploadableVideoMaxMb,
          documentMax: UploadableDocumentMaxMb,
          textMax: UploadableTxtMaxMb,
          size: maxFileSize
        }
      )
    );
  }

  // Starterの場合はFrontでPDFページ数と音声の長さもチェック
  // - 有料プランでは実質無制限だが、Starterだとエラーになることが多くなると推測しており、
  //   結構長い時間まってエラーになると心象が悪いため早めにだす。
  if (isStarter) {
    // PDFのページ数を判定
    if (fileType == FileType.DOCUMENT) {
      const UploadableDocumentMaxPages = 5; // 最大ページ数を設定

      try {
        const pdfPageCount = await getPdfPageCount(file);
        if (pdfPageCount > UploadableDocumentMaxPages) {
          return (
            // t:Starterプランでは、PDFのページ数は{{max}}ページまでとなっております。
            //   ページ数を調整いただくか、プランのアップグレードをご検討ください。
            //   (選択されたファイルのページ数: {{count}}ページ)
            //
            //   ※ 有料プランでのPDFアップロード制限は「20MBまで 3000ページまで」となっております。
            i18next.t(
              'file:error.starterPlanPdfPageLimit',
              {
                max: UploadableDocumentMaxPages,
                count: pdfPageCount
              }
            )
          );
        }
      } catch (err) {
        captureException(err);
        console.debug(err);
      }
    }

    // 音声の長さを判定
    if (fileType == FileType.AUDIO) {
      const UploadableAudioMaxDuration = 60;
      try {
        const audioDuration = await getAudioDuration(file);
        if (audioDuration > UploadableAudioMaxDuration) {
          return (
            i18next.t(
              // t:Starterプランでは、音声の長さは{{max}}秒までとなっております。
              //   長さを調整いただくか、プランのアップグレードをご検討ください。
              //   (選択されたファイルの長さ: {{duration}}秒)
              //  ※ 有料プランでの音声アップロード制限は「100MBまで 8.5時間まで」となっております。
              'file:error.starterPlanAudioDurationLimit',
              { max: UploadableAudioMaxDuration, duration: audioDuration }
            )
          );
        }
      } catch (err) {
        captureException(err);
        console.debug(err);
      }
    }

    // 動画
    if (fileType == FileType.VIDEO) {
      const UploadableVideoMaxDuration = 60;
      try {
        const audioDuration = await getAudioDuration(file);
        if (audioDuration > UploadableVideoMaxDuration) {
          return (
            i18next.t(
              // t:Starterプランでは、動画の長さは{{max}}秒までとなっております。
              //   長さを調整いただくか、プランのアップグレードをご検討ください。
              //   (選択されたファイルの長さ: {{duration}}秒)
              //  ※ 有料プランでの音声アップロード制限は「100MBまで 1時間まで」となっております。
              'file:error.starterPlanVideoDurationLimit',
              { max: UploadableVideoMaxDuration, duration: audioDuration }
            )
          );
        }
      } catch (err) {
        captureException(err);
        console.debug(err);
      }
    }
  }

  return;
}

export const checkUploadFileAndModel = (file: File | FileUpload, modelIds: string[], currentMembership?: LoginUserMembership) : string | undefined => {
  // ファイルタイプを特定
  let fileType: FileType;
  if (file instanceof File) {
    const fileTypeTemp = convertMimeTypeToFileType(file.type);
    if (fileTypeTemp === null) {
      throw new Error('Invalid file type');
    }
    fileType = fileTypeTemp;
  } else {
    fileType = file.type;
  }

  // 送信対象となるモデルを取得
  if (modelIds.length === 0) {
    modelIds = [DEFAULT_AI_MODEL_ID];
  }
  const models = getAIModelConsts(modelIds);

  // 使っては行けないモデルを抽出
  const invalidModels: AIModelConst[] = []
  for (const model of models) {
    if (!model.uploadableFileTypes.includes(fileType)) {
      invalidModels.push(model);
    }
  }

  // 使っては行けないモデルがある場合、エラーメッセージを返す
  if (invalidModels.length > 0) {
    // どうサポートしてないかを表示
    const fileTypeLabel = getLabelForFileType(fileType);
    const mainMsg = (
      // t:下記のAIモデルは「{{fileTypeLabel}}」の添付をサポートしていません。
      //   メンションまたは添付ファイルを見直してください。
      i18next.t(
        'file:error.unsupportedModelForFileType',
        { fileTypeLabel, }
      ) + "\n" + invalidModels.map(model => `・${model.name}`).join('\n')
    );

    // 送信可能なファイル情報メッセージを作成
    let additionalMsg = "";
    if (currentMembership) {
      const usableAiModelIds = currentMembership.team.quota.usableAiCodes || []
      if (usableAiModelIds.length > 0) {
        // t:[添付可能なファイル]
        additionalMsg = "\n\n" + i18next.t('file:attachableFiles');

        const usableAiModels = getAIModelConsts(usableAiModelIds);
        for (const model of usableAiModels) {
          if (model.deprecated) {
            continue;
          }
          // t:{{modelName}} → {{fileTypes}}
          additionalMsg += "\n・" + i18next.t('file:modelFileTypes', {
            modelName: model.name,
            fileTypes: model.uploadableFileTypes.map(type => getLabelForFileType(type)).join(', ')
          });
        }
      }
    }

    return mainMsg + additionalMsg;
  }

  return undefined;
}

export const formatBytes = (bytes: number): string => {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const getUploadableMaxMbFor = (fileType: FileType, isStarter: boolean) => {
  switch (fileType) {
    case FileType.TEXT:
      return UploadableTxtMaxMb;
    case FileType.IMAGE:
      return isStarter ? UploadableNotTextMaxMbForStarter : UploadableImageMaxMb;
    case FileType.AUDIO:
      return isStarter ? UploadableNotTextMaxMbForStarter : UploadableAudioMaxMb;
    case FileType.VIDEO:
      return isStarter ? UploadableNotTextMaxMbForStarter : UploadableVideoMaxMb;
    case FileType.DOCUMENT:
      return isStarter ? UploadableNotTextMaxMbForStarter  : UploadableDocumentMaxMb;
  }
  return 0;
}

const getPdfPageCount = async (file: File): Promise<number> => {
  return new Promise<number>((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = async function () {
      const typedarray = new Uint8Array(this.result as ArrayBuffer);
      try {
        const pdf = await pdfjsLib.getDocument(typedarray).promise;
        resolve(pdf.numPages);
      } catch (err) {
        reject(err);
      }
    };

    fileReader.onerror = function (err) {
      reject(err);
    };

    fileReader.readAsArrayBuffer(file);
  });
};

const getAudioDuration = (file: File): Promise<number> => {
  return new Promise<number>((resolve, reject) => {
    const url = URL.createObjectURL(file);
    const audio = new Audio(url);

    const onLoadedMetadata = () => {
      resolve(audio.duration);
      URL.revokeObjectURL(url);
      audio.removeEventListener('loadedmetadata', onLoadedMetadata);
    };

    const onError = (e: Event) => {
      reject(e);
      URL.revokeObjectURL(url);
      audio.removeEventListener('error', onError);
    };

    audio.addEventListener('loadedmetadata', onLoadedMetadata);
    audio.addEventListener('error', onError);
  });
};

