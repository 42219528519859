import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Close, Delete } from '@mui/icons-material'; // Deleteアイコンをインポート
import { useAppDispatch } from '@/store';
import { showUnknownErrorModal } from '../../generic/errorModal.slice';
import { captureException } from '@sentry/react';
import { showToast } from '../../generic/toast.slice';
import { QuickPrompt, QuickPromptSet } from '../../quickPrompt/quickPrompt.type';
import { Team } from '../../team/team.type';
import { MembershipGroup } from '../../membership-group/membership-group.type';
import { MembershipWithUser } from '../../membership/membership.type';
import apiAxios from '@/lib/axios';
import { QpAuthorityType } from '../../quickPrompt/quickPrompt.constant';
import { QpAuthority } from '../quickPromptManagement.type';
import { useConfirmModal } from '../../generic/hooks/useConfirmModal';
import { setIsSharedForQp, setIsSharedForQpSet } from '../../quickPrompt/quickPrompt.slice';

interface Props {
  sharedObject: QuickPrompt | QuickPromptSet;
  userAuthority: QpAuthorityType;
  team: Team;
  open: boolean;
  onClose: () => void;
}

const QpShareModal: React.FC<Props> = ({ sharedObject, team, open, onClose }) => {
  const dispatch = useAppDispatch();
  const { setConfirmModal } = useConfirmModal();
  const [authorities, setAuthorities] = useState<QpAuthority[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAdding, setIsAdding] = useState<boolean>(false);

  const [targetType, setTargetType] = useState<'team' | 'group' | 'member'>('team');
  const [authorityType, setAuthorityType] = useState<QpAuthorityType>(QpAuthorityType.USER);
  const [groups, setGroups] = useState<MembershipGroup[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [members, setMembers] = useState<MembershipWithUser[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isLoadingGroups, setIsLoadingGroups] = useState<boolean>(false);
  const [isLoadingMembers, setIsLoadingMembers] = useState<boolean>(false);

  const isQpSet = !('quickPromptSetId' in sharedObject);
  const authApiPrefix =
    isQpSet
      ? `/quick-prompt-sets/${sharedObject.id}/authorities`
      : `/quick-prompts/${sharedObject.id}/authorities`;

  // 権限一覧とグループ一覧を同時に取得
  useEffect(() => {
    if (open) {
      fetchDataAndGroups();
    }
  }, [open]);

  const fetchDataAndGroups = async () => {
    setIsLoading(true);
    setIsLoadingGroups(true);
    try {
      const [authorityResponse, groupResponse] = await Promise.all([
        apiAxios.get(authApiPrefix),
        apiAxios.get('/membership-groups', {
          params: {
            offset: 0,
            limit: 999,
            orderBy: 'created_at',
            order: 'desc',
          },
        }),
      ]);
      setAuthorities(authorityResponse.data || []);
      setGroups(groupResponse.data.membershipGroups);
    } catch (error) {
      console.debug(error);
      captureException(error);
      dispatch(showUnknownErrorModal());
      setAuthorities([]);
      setGroups([]);
    } finally {
      setIsLoading(false);
      setIsLoadingGroups(false);
    }
  };

  // 権限の変更
  const handleAuthorityChange = async (authorityId: string, newAuthorityType: QpAuthorityType) => {
    try {
      await apiAxios.put(`${authApiPrefix}/${authorityId}`, {
        authorityType: newAuthorityType,
      });
      dispatch(showToast({ message: '権限を更新しました', severity: 'success' }));
      fetchDataAndGroups();
    } catch (error) {
      console.debug(error);
      captureException(error);
      dispatch(showUnknownErrorModal());
    }
  };

  // 削除機能の追加
  const handleDeleteAuthority = async (authorityId: string) => {
    const deletedAuthority = authorities.find((auth) => auth.id === authorityId);
    const authorityTypeLabel = deletedAuthority?.authorityType === 'owner'
      ? 'オーナー'
      : deletedAuthority?.authorityType === 'editor'
      ? '編集者'
      : '利用者'
    ;

    const deleteAuth = async () => {
      try {
        const res = await apiAxios.delete(`${authApiPrefix}/${authorityId}`);
        dispatch(showToast({ message: '権限を削除しました', severity: 'success' }));
        fetchDataAndGroups();

        const isShared = res.data['isShared'] || false;
        if (isQpSet) {
          dispatch(setIsSharedForQpSet({id: sharedObject.id, isShared}));
        } else {
          dispatch(setIsSharedForQp({id: sharedObject.id, isShared} ));
        }
      } catch (error) {
        console.debug(error);
        captureException(error);
        dispatch(showUnknownErrorModal());
      }
    }

    setConfirmModal(
      "権限削除確認",
      `下記の権限を削除してよろしいですか？

      ${deletedAuthority?.subjectName}
      (${authorityTypeLabel})`,
      deleteAuth,
    );
  };

  // メンバー検索
  useEffect(() => {
    if (targetType === 'member') {
      fetchMembers();
    }
  }, [targetType, searchQuery]);

  const fetchMembers = async () => {
    setIsLoadingMembers(true);
    try {
      const response = await apiAxios.get('/memberships', {
        params: {
          offset: 0,
          limit: 50,
          orderBy: 'created_at',
          order: 'desc',
          isRegistered: true,
          name: searchQuery || undefined,
          email: searchQuery || undefined,
        },
      });
      setMembers(response.data.memberships);
    } catch (error) {
      console.debug(error);
      captureException(error);
      dispatch(showUnknownErrorModal());
    } finally {
      setIsLoadingMembers(false);
    }
  };

  const handleRegister = async () => {
    const subjectIds =
      targetType === 'team'
        ? [team.id]
        : targetType === 'group'
        ? selectedGroups
        : selectedMembers;

    try {
      const res = await apiAxios.post(authApiPrefix, {
        subjectIds: subjectIds,
        authorityType: authorityType,
      });
      const createdAuthorities = res.data;
      dispatch(showToast({ message: '権限を追加しました', severity: 'success' }));
      setIsAdding(false);
      setAuthorities([...createdAuthorities, ...authorities]);
      if (isQpSet) {
        dispatch(setIsSharedForQpSet({id: sharedObject.id, isShared: true}));
      } else {
        dispatch(setIsSharedForQp({id: sharedObject.id, isShared: true} ));
      }
    } catch (error) {
      console.debug(error);
      captureException(error);
      dispatch(showUnknownErrorModal());
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      {isAdding ? (
        <>
          <DialogTitle>新規追加</DialogTitle>
          <DialogContent dividers>
            <FormControl component="fieldset" sx={{ mt: 2 }}>
              <RadioGroup
                row
                value={targetType}
                onChange={(e) => setTargetType(e.target.value as 'team' | 'group' | 'member')}
              >
                <FormControlLabel value="team" control={<Radio />} label="チーム" />
                <FormControlLabel value="group" control={<Radio />} label="グループ" />
                <FormControlLabel value="member" control={<Radio />} label="メンバー" />
              </RadioGroup>
            </FormControl>
            <FormControl variant="standard" sx={{ mt: 2, minWidth: 120 }}>
              <InputLabel>権限</InputLabel>
              <Select
                value={authorityType}
                onChange={(e) => setAuthorityType(e.target.value as QpAuthorityType)}
              >
                <MenuItem value="owner">オーナー</MenuItem>
                <MenuItem value="editor">編集者</MenuItem>
                <MenuItem value="user">利用者</MenuItem>
              </Select>
            </FormControl>

            {targetType === 'group' && (
              <>
                {isLoadingGroups ? (
                  <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box sx={{ maxHeight: 200, overflowY: 'auto', mt: 2 }}>
                    {groups.map((group) => (
                      <FormControlLabel
                        key={group.id}
                        control={
                          <Checkbox
                            checked={selectedGroups.includes(group.id)}
                            onChange={(e) => {
                              const newSelected = e.target.checked
                                ? [...selectedGroups, group.id]
                                : selectedGroups.filter((id) => id !== group.id);
                              setSelectedGroups(newSelected);
                            }}
                          />
                        }
                        label={group.name}
                      />
                    ))}
                  </Box>
                )}
              </>
            )}

            {targetType === 'member' && (
              <>
                <TextField
                  label="検索"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  fullWidth
                  sx={{ mt: 2 }}
                />
                {isLoadingMembers ? (
                  <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <List sx={{ maxHeight: 200, overflowY: 'auto' }}>
                    {members.map((member) => (
                      <ListItem key={member.id} dense>
                        <ListItemAvatar>
                          {member.user.picture ? (
                            <Avatar src={member.user.picture} />
                          ) : (
                            <Avatar>{
                              member.user.name?.charAt(0) ||
                              member.user.email.charAt(0)
                            }</Avatar>
                          )}
                        </ListItemAvatar>
                        <ListItemText primary={member.user.name || member.user.email} />
                        <Checkbox
                          edge="end"
                          checked={selectedMembers.includes(member.id)}
                          onChange={(e) => {
                            const newSelected = e.target.checked
                              ? [...selectedMembers, member.id]
                              : selectedMembers.filter((id) => id !== member.id);
                            setSelectedMembers(newSelected);
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsAdding(false)}>戻る</Button>
            <Button
              variant="contained"
              onClick={handleRegister}
              disabled={
                targetType === 'group'
                  ? selectedGroups.length === 0
                  : targetType === 'member'
                  ? selectedMembers.length === 0
                  : false
              }
            >
              登録
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>
            {`「${sharedObject.name}」を共有`}
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Box display="flex" justifyContent="flex-end" mb={2}>
              <Button variant="contained" onClick={() => setIsAdding(true)}>
                新規追加
              </Button>
            </Box>
            <Typography variant="h6">アクセスできるユーザー</Typography>
            {isLoading ? (
              <Box display="flex" justifyContent="center" mt={2}>
                <CircularProgress />
              </Box>
            ) : (
              <List>
                {Array.isArray(authorities) &&
                  authorities.map((authority) => (
                    <ListItem
                      key={authority.subjectId}
                      sx={{ borderBottom: '1px solid #e0e0e0', display: 'flex', alignItems: 'center' }}
                      secondaryAction={
                        <>
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleDeleteAuthority(authority.id)}
                            edge="end"
                            size='small'
                            sx={{ marginRight: "-10px" }}
                          >
                            <Delete />
                          </IconButton>
                        </>
                      }
                    >
                      <ListItemAvatar>
                        {authority.subjectType === 'membership' && authority.iconUrl ? (
                          <Avatar src={authority.iconUrl} />
                        ) : (
                          <Avatar>
                            {authority.subjectType === 'team'
                              ? 'T'
                              : authority.subjectType === 'group'
                              ? 'G'
                              : ''}
                          </Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText primary={authority.subjectName} />
                      <FormControl variant="standard" sx={{ minWidth: 120 }}>
                        <Select
                          value={authority.authorityType}
                          onChange={(e) =>
                            handleAuthorityChange(
                              authority.id,
                              e.target.value as QpAuthorityType,
                            )
                          }
                        >
                          <MenuItem value="owner">オーナー</MenuItem>
                          <MenuItem value="editor">編集者</MenuItem>
                          <MenuItem value="user">利用者</MenuItem>
                        </Select>
                      </FormControl>
                    </ListItem>
                  ))}
              </List>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>閉じる</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default QpShareModal;
