import { QpSelectBoxDetail } from "@/components/features/quickPrompt/quickPrompt.type";
import QpChoicePropertySettingFields from "./QpChoicePropertySettingFields";
import { ReqQpPropertyEdit } from "../../qpEditForm.type";

interface Props {
  property: ReqQpPropertyEdit;
  allProperties: ReqQpPropertyEdit[];
  detail: QpSelectBoxDetail;
  onChange: (data: QpSelectBoxDetail) => void;
}

function QpSelectBoxSettingFields({
  property,
  allProperties,
  detail,
  onChange,
}: Props) {

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   onChange({
  //     ...detail,
  //     [name]: value,
  //   });
  // };

  return (
    <>
      <QpChoicePropertySettingFields
        property={property}
        allProperties={allProperties}
        detail={detail}
        onChange={onChange}
      />
    </>
  );
}

export default QpSelectBoxSettingFields;
