import QpEditContainer from "@/components/features/quickPromptEdit/containers/QpEditContainer";
import { useParams } from "react-router-dom";

const QuickPromptsEditPage = () => {
  const { quickPromptId } = useParams<{ quickPromptId: string }>();
  return <QpEditContainer quickPromptId={quickPromptId}/>;
};

export default QuickPromptsEditPage;

