import React, { FormEvent, useEffect, useState } from 'react';
import { SseEventThreadCreated, ThreadCreateType } from '../../thread/thread.type';
import { QuickPromptWithOptionalVersion, QuickPromptWithVersion } from '../quickPrompt.type';
import { IChangeEvent } from '@rjsf/core';
import useThreadApi from '../../thread/hooks/useThreadApi';
import { useErrorModal } from '../../generic/hooks/useErrorModal';
import { handleDlpCheck } from '../../thread/thread.utils';
import { useConfirmModal } from '../../generic/hooks/useConfirmModal';
import { useThreadAlertModal } from '../../thread/hooks/useThreadAlertModal';
import apiAxios from '@/lib/axios';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuickPrompt } from '../quickPrompt.slice';
import { RootState } from '@/store';
import { RECOMMEND_QP_INFO_LIST } from '../quickPrompt.constant';
import QpFormPresenter from '../presenters/QpFormPresenter';
import { AIModelConsts } from '../../aiModel/aiModel.constant';
import { FileUpload } from '../../file/file.type';
import { checkUploadFile, checkUploadFileAndModel, FileUploadError, upload } from '../../file/file.utils';
import { getCurrentMembership, getCurrentSubscription } from '../../auth/auth.type';
import { captureException } from '@sentry/react';
import useLoading from '../../generic/hooks/useLoading';
import { PlanType } from '../../subscription/subscription.constant';
import { useTranslation } from 'react-i18next';

const OPTIONS_DISPLAY = 'optionsDisplay';

interface QpFormContainerProps {
  quickPromptOrId: string|QuickPromptWithOptionalVersion;
  versionId?: string;
  input?: { [key: string]: unknown };
  initialFile?: FileUpload;
  onSelectFile?: (file: File | undefined) => void;
  hideSendButton?: boolean;
  formChangedCallback?: (formData: object) => void;
  isTestMode?: boolean;
  onThreadCreated?: (data: SseEventThreadCreated) => void;
}

const QpFormContainer: React.FC<QpFormContainerProps> = ({
  quickPromptOrId,
  versionId,
  input,
  initialFile,
  onSelectFile,
  formChangedCallback,
  onThreadCreated,
  hideSendButton = false,
  isTestMode = false,
}) => {
  const dispatch = useDispatch();
  const { createThread } = useThreadApi();
  const { setErrorModal, setUnknownErrorModal } = useErrorModal();
  const { setConfirmModal } = useConfirmModal();
  const [formData, setFormData] = useState<object>({});
  const [selectedFile, setSelectedFile] = useState<File | FileUpload | undefined>(undefined);
  const [optionOpened, setOptionOpened] = useState(false);
  const [ fileUploadProgress, setFileUploadProgress ] = useState<string | undefined>(undefined);
  const quickPromptSets = useSelector((state: RootState) => state.quickPrompt.quickPromptSets);
  const threadOnGenerating = useSelector((state: RootState) => state.thread.threadOnGenerating);
  const [selectedQuickPrompt, setSelectedQuickPrompt] = useState<QuickPromptWithVersion | undefined>(undefined);
  const { showQuotaExceededModal } = useThreadAlertModal();
  const queryParams = new URLSearchParams(window.location.search);
  const recommendValue = queryParams.get('recommend');
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const membership = getCurrentMembership(loginUser)
  const { setIsLoading } = useLoading();
  const { t } = useTranslation();

  const currentSub = getCurrentSubscription(loginUser);
  const isStarter = !!(currentSub && currentSub.plan.type == PlanType.STARTER);

  const setDefaultFormData = (qp: QuickPromptWithVersion) => {
    // propsでinputが渡されてる場合(編集の場合)はそれをそのままセット
    if (input) {
      setFormData(input);
      setOptionOpened(input[OPTIONS_DISPLAY] as boolean || false);
      return;
    }

    // 上記以外の場合は新規登録
    let baseInput: { [key: string]: unknown } = {};

    // 最初の要素名
    let firstProp: string | null = null;
    const version = qp.targetVersion || qp.latestVersion;
    const uiSchema = version.uiSchema;
    if (uiSchema && 'ui:order' in uiSchema) {
      const order = uiSchema['ui:order'] as string[];
      if (order.length > 0) {
        firstProp = order[0];
      }
    }

    // 最新の入力があればそれを使う
    if (qp.latestInput) {
      baseInput = qp.latestInput as { [key: string]: unknown };

      // 最初の要素は空にする
      if (firstProp) {
        baseInput = { ...baseInput, [firstProp]: '' };
      }

      // AIモデルは非推奨になったものは除外
      if (baseInput['aiModelCodes'] && Array.isArray(baseInput['aiModelCodes'])) {
        const inputtedAiModels = baseInput['aiModelCodes'] as string[];
        const validAiModels = AIModelConsts.filter(model => !model.deprecated).map(model => model.id);
        baseInput['aiModelCodes'] = inputtedAiModels.filter(modelId => validAiModels.includes(modelId));
      }

    } else if (recommendValue && firstProp) {
      // おすすめから飛んだ場合はサンプルを表示
      RECOMMEND_QP_INFO_LIST.forEach((rqp) => {
        if (
          rqp.code === qp.officialCode ||
          rqp.code + "_en" === qp.officialCode  // 英語対応
        ) {
          baseInput = { ...baseInput, [firstProp]: rqp.sampleT(t) };
        }
      });
    }

    setFormData(baseInput);
    setOptionOpened(baseInput[OPTIONS_DISPLAY] as boolean || false);
  };

  useEffect(() => {
    const fetch = async () => {
      const path = `/quick-prompts/${quickPromptOrId}${versionId ? `?versionId=${versionId}` : ''}`;
      const response = await apiAxios.get<QuickPromptWithVersion>(path);
      console.debug(`${path} response`, response);
      const updatedQuickPrompt = response.data;
      if (!versionId) {
        dispatch(updateQuickPrompt(updatedQuickPrompt));
      }
      setSelectedQuickPrompt(updatedQuickPrompt);
      setDefaultFormData(updatedQuickPrompt);
    };

    // quickPromptOrId が falsy な値の場合は早期リターン
    if (!quickPromptOrId) {
      return;
    }

    // quickPromptOrId がオブジェクトで versionId が指定されていない場合、そのままセット
    if (!versionId && typeof quickPromptOrId !== 'string') {
      const qp: QuickPromptWithOptionalVersion = quickPromptOrId;
      if ('latestVersion' in qp && qp.latestVersion) {
        setSelectedQuickPrompt(qp);
        return;
      }
    }

    // 現在の selectedQuickPrompt が quickPromptOrId と一致する場合、何もしない
    if (
      quickPromptOrId &&
      selectedQuickPrompt &&
      typeof quickPromptOrId === 'string' &&
      selectedQuickPrompt.id === quickPromptOrId &&
      (!versionId || (selectedQuickPrompt.targetVersion || selectedQuickPrompt.latestVersion).id === versionId)
    ) {
      return;
    }

    // quickPromptOrId が新しい ID の場合、selectedQuickPrompt を undefined に設定してローディングを表示
    if (typeof quickPromptOrId === 'string') {
      setSelectedQuickPrompt(undefined);
    }

    // quickPromptSets に既にデータが存在するか確認
    const quickPrompts = quickPromptSets.map((qps) => qps.quickPrompts).flat();
    const quickPrompt = quickPrompts.find((qp) => qp.id === quickPromptOrId);
    if (
      quickPrompt &&
      'latestVersion' in quickPrompt &&
      quickPrompt.latestVersion &&
      (!versionId || quickPrompt.latestVersion.id === versionId)
    ) {
      const qp = quickPrompt as QuickPromptWithVersion;
      setSelectedQuickPrompt(qp);
      setDefaultFormData(qp);
      return;
    }

    // データが存在しない場合、API からフェッチ
    fetch();
  }, [dispatch, quickPromptOrId, versionId, quickPromptSets, selectedQuickPrompt]);

  if (!selectedQuickPrompt) {
    return <QpFormPresenter loading={true} />;
  }

  const qp = selectedQuickPrompt;

  // フォームの変更を監視する関数
  const handleFormChange = (data: IChangeEvent) => {
    setOptionOpened(data.formData[OPTIONS_DISPLAY]);
    setFormData(data.formData);
    formChangedCallback?.(data.formData as object);
  };

  const onSelectFileInternal = async (file: File | undefined) => {
    if (onSelectFile) {
      onSelectFile(file);
      return;
    }

    const errorMsg = await checkUploadFile(file, isStarter);
    if (errorMsg) {
      // t:ファイル添付エラー
      setErrorModal(t("quickPrompt:fileUpload.error"), errorMsg);
      return;
    }
    setSelectedFile(file);
  };

  const handleSendMessage = async (data: IChangeEvent, _: FormEvent) => {
    const postQuickPrompt = async (skipDlpBlock: boolean = false) => {
      setIsLoading(true);
      // 該当ファイルをアップロードできるかモデルかチェック
      if (selectedFile) {
        const errorMsg = checkUploadFileAndModel(
          selectedFile, data.formData['aiModelCodes'] || [], membership
        );
        if (errorMsg) {
          // t:ファイルタイプエラー
          setErrorModal(t("quickPrompt:fileUpload.typeError"), errorMsg);
          setIsLoading(false);
          return;
        }
      }

      // ファイルが選択されている場合はアップロード
      let fileUpload: FileUpload | undefined;
      if (selectedFile && selectedFile instanceof File) {
        try {
          fileUpload = await upload(selectedFile, setFileUploadProgress);
          setFileUploadProgress(undefined);
        } catch (err) {
          setFileUploadProgress(undefined);
          captureException(err);
          console.error('File upload failed:', err);
          setIsLoading(false);
          if (err instanceof FileUploadError) {
            setErrorModal(
              // t:ファイルアップロードエラー
              t("quickPrompt:fileUpload.uploadError"),
              err.message + (
                // t:ブラウザをリロードするか、しばらくしてから再度お試しください。
                err.retryable ? t("quickPrompt:fileUpload.retryMessage") : ""
              )
            );
          } else {
            setUnknownErrorModal();
          }
          return;
        }
      }

      createThread(
        {
          type: ThreadCreateType.QUICK_PROMPT,
          quickPromptDetail: {
            versionId: (qp.targetVersion || qp.latestVersion).id,
            formInput: data.formData,
          },
          skipDlpBlock: skipDlpBlock,
          fileUploadId: fileUpload?.id,
          isTestMode: isTestMode,
        },
        {
          onThreadCreated: (resData) => {
            setIsLoading(false);

            if (onThreadCreated) {
              onThreadCreated(resData);
            }

            // テストモードの場合はフォームのクリアやStoreの更新は行わない
            if (isTestMode) return;

            const updatedQp = { ...qp, latestInput: data.formData };
            dispatch(updateQuickPrompt(updatedQp));
            setFormData({});
          },
          onBlockedByDlp: (result) => handleDlpCheck(t, result, postQuickPrompt, setErrorModal, setConfirmModal),
          onQuotaExceeded: (data) => showQuotaExceededModal(data.quotaCheckResult),
          onClose: () => {
            setIsLoading(false);
          },
        }
      );
    };

    postQuickPrompt();
  };

  const showTrySend = !!(
    recommendValue && !qp.latestInput
  );

  return (
    <QpFormPresenter
      loading={false}
      qp={selectedQuickPrompt}
      formData={formData}
      initialFile={initialFile}
      selectedFile={selectedFile}
      onSelectFile={onSelectFileInternal}
      onGenerating={threadOnGenerating}
      optionOpened={optionOpened}
      handleFormChange={handleFormChange}
      handleSendMessage={handleSendMessage}
      showTrySend={showTrySend}
      hideSendButton={hideSendButton}
      fileUploadProgress={fileUploadProgress}
    />
  );
};

export default QpFormContainer;
