import QpFormContainer from "@/components/features/quickPrompt/containers/QpFormContainer";
import { useParams } from "react-router-dom";

const QuickPromptsThreadsNewPage = () => {
  const { quickPromptId } = useParams<{ quickPromptId: string }>();
  if (!quickPromptId) {
    return null;
  }
  return <QpFormContainer quickPromptOrId={quickPromptId}/>;
};

export default QuickPromptsThreadsNewPage;

