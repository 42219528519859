import { useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
} from '@mui/material';
import { ReqQpPropertyEdit } from '../qpEditForm.type';
import { QpPropertyType, ReqQpPropertyDetail } from '../../quickPrompt/quickPrompt.type';

// 画像をインポート
import TextFieldImage from '@/assets/images/qpEditForm/TextField.png';
import TextAreaImage from '@/assets/images/qpEditForm/TextArea.png';
import TextAreaWithToolbarImage from '@/assets/images/qpEditForm/TextAreaWithToolbar.png';
import RadioButtonImage from '@/assets/images/qpEditForm/RadioButton.png';
import SelectBoxImage from '@/assets/images/qpEditForm/SelectBox.png';
import CheckBoxImage from '@/assets/images/qpEditForm/CheckBox.png';
import SingleCheckBoxImage from '@/assets/images/qpEditForm/SingleCheckBox.png';
import SelectAiModelsImage from '@/assets/images/qpEditForm/SelectAiModels.png';
import ToggleAdvancedSettingImage from '@/assets/images/qpEditForm/ToggleAdvancedSetting.png';
import { generatePropertyKey } from '../qpEditForm.utils';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getCurrentMembership } from '../../auth/auth.type';

interface Props {
  open: boolean;
  onClose: () => void;
  onAddItem: (item: ReqQpPropertyEdit) => void;
  existingProperties: ReqQpPropertyEdit[];
}

function QpEditStep2AddItemModal({ open, onClose, onAddItem, existingProperties }: Props) {
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const membership = getCurrentMembership(loginUser)
  const teamConfig = membership?.team?.config
  const enableTextAreaWithToolbar = teamConfig?.isFileAttachmentEnabled;

  const componentList = [
    {
      id: 'textField',
      name: 'テキストフィールド',
      description: '短い文字列を入力するための入力項目です。',
      image: TextFieldImage,
    },
    {
      id: 'textArea',
      name: 'テキストエリア',
      description: '長い文章を入力するための入力項目です。',
      image: TextAreaImage,
    },
    {
      id: 'radioButton',
      name: 'ラジオボタン',
      description: '選択肢から一つだけ選択できる入力項目です。',
      image: RadioButtonImage,
    },
    {
      id: 'selectBox',
      name: 'セレクトボックス',
      description: '選択肢から一つだけ選択できる入力項目です。',
      image: SelectBoxImage,
    },
    {
      id: 'checkBox',
      name: 'チェックボックス',
      description: '選択肢から複数選択できる入力項目です。',
      image: CheckBoxImage,
    },
    {
      id: 'singleCheckBox',
      name: '単一チェックボックス',
      subName: '単一チェックボックス',
      description: 'オン/オフを切り替えられる入力項目です。',
      image: SingleCheckBoxImage,
    },
    ...(enableTextAreaWithToolbar ? [{
      id: 'textAreaWithToolbar',
      name: '多機能テキストエリア',
      description: '文字入力だけでなく、ファイルアップロード等もできる入力項目です。フォームに一つだけ追加可能です。',
      image: TextAreaWithToolbarImage,
    }] : []),
    {
      id: 'selectAiModels',
      name: 'AIモデル選択',
      description: 'AIモデルを選択する項目です。AIモデルの一覧から複数選択できます。フォームに一つだけ追加可能です。',
      image: SelectAiModelsImage,
    },
    {
      id: 'toggleAdvancedSetting',
      name: '詳細設定の開閉',
      description: '詳細設定を開閉する切り替えするための入力項目です。これより下の項目は詳細設定が開いている時のみ表示されます。フォームに一つだけ追加可能です。',
      image: ToggleAdvancedSettingImage,
    },
  ];

  const existingTypes = useMemo(
    () => existingProperties.map((prop) => prop.type),
    [existingProperties]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>項目を選択</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {componentList.map((component) => {
            const isDisabled =
              (
                component.id === 'textAreaWithToolbar' &&
                existingTypes.includes(QpPropertyType.TEXT_AREA_WITH_TOOLBAR)
              ) ||
              (
                component.id === 'selectAiModels' &&
                existingTypes.includes(QpPropertyType.SELECT_AI_MODELS)
              ) ||
              (
                component.id === 'toggleAdvancedSetting' &&
                existingTypes.includes(QpPropertyType.TOGGLE_ADVANCED_SETTING)
              );

            return (
              <Grid item xs={6} sm={4} md={3} key={component.id}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
                  <CardActionArea
                    onClick={() => {
                      if (!isDisabled) {
                        const newProperty = createNewProperty(component.id, existingProperties);
                        onAddItem(newProperty);
                      }
                    }}
                    disabled={isDisabled}
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      height: '100%',
                      position: 'relative',
                      ...(isDisabled && {
                        '&::after': {
                          content: '"既に追加済みです\\A(フォームに1つのみ追加可)"',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          color: 'red',
                          textAlign: 'center',
                          whiteSpace: 'pre',
                          fontWeight: 'bold',
                          zIndex: 1000,
                        },
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          zIndex: 500,
                        },
                      }),
                    }}
                  >
                    <CardContent sx={{ width: '100%', zIndex: 2 }}>
                      <Typography variant="body1" gutterBottom>
                        {component.name}
                      </Typography>
                      <CardMedia
                        component="img"
                        sx={{ objectFit: 'contain', height: 140, width: '100%' }}
                        image={component.image}
                        alt={component.name}
                      />
                      <Typography variant="caption" color="textSecondary">
                        {component.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>閉じる</Button>
      </DialogActions>
    </Dialog>
  );
}

function createNewProperty(
  type: string,
  existingProperties: ReqQpPropertyEdit[]
): ReqQpPropertyEdit {
  const id = Date.now().toString();
  const qpType = componentTypeMap[type];
  const label = getLabel(type, existingProperties);
  const key = generatePropertyKey(label);
  return {
    id,
    key,
    label,
    type: qpType,
    detail: getDefaultDetail(type),
  };
}

const componentTypeMap: { [key: string]: QpPropertyType } = {
  textField: QpPropertyType.TEXT_FIELD,
  textArea: QpPropertyType.TEXT_AREA,
  checkBox: QpPropertyType.CHECK_BOX,
  radioButton: QpPropertyType.RADIO_BUTTON,
  selectBox: QpPropertyType.SELECT_BOX,
  singleCheckBox: QpPropertyType.SINGLE_CHECK_BOX,
  textAreaWithToolbar: QpPropertyType.TEXT_AREA_WITH_TOOLBAR,
  selectAiModels: QpPropertyType.SELECT_AI_MODELS,
  toggleAdvancedSetting: QpPropertyType.TOGGLE_ADVANCED_SETTING,
};

/**
 * 新しいプロパティのラベルを生成する
 *
 * @param type - プロパティのタイプ ('textField', 'textArea' など)
 * @param existingProperties - 既存のプロパティ配列
 * @returns 生成されたラベル文字列
 *
 * @example
 * // 既存のプロパティがない場合
 * getLabel('textField', []) // => 'テキストフィールド'
 *
 * // 既存のプロパティがある場合は連番を付与
 * getLabel('textField', [{label: 'テキストフィールド'}]) // => 'テキストフィールド2'
 *
 * // 既に連番がある場合は次の番号を付与
 * getLabel('textField', [
 *   {label: 'テキストフィールド'},
 *   {label: 'テキストフィールド2'}
 * ]) // => 'テキストフィールド3'
 *
 * // 欠番がある場合は最大の番号+1を付与
 * getLabel('textField', [
 *   {label: 'テキストフィールド'},
 *   {label: 'テキストフィールド3'}
 * ]) // => 'テキストフィールド4'
 */
function getLabel(
  type: string,
  existingProperties: ReqQpPropertyEdit[]
): string {
  const baseLabel = (() => {
    switch (type) {
      case 'textField':
        return 'テキストフィールド';
      case 'textArea':
        return 'テキストエリア';
      case 'textAreaWithToolbar':
        return '多機能テキストエリア';
      case 'checkBox':
        return 'チェックボックス';
      case 'radioButton':
        return 'ラジオボタン';
      case 'selectBox':
        return 'セレクトボックス';
      case 'singleCheckBox':
        return '単一チェックボックス';
      case 'selectAiModels':
        return 'AIモデル選択';
      case 'toggleAdvancedSetting':
        return '詳細設定';
      default:
        return '';
    }
  })();

  if (!baseLabel) return '';

  // 既存のラベルから同じベースラベルを持つものを抽出し、番号を取得
  const existingNumbers = existingProperties
    .map(p => p.label)
    .filter(label => label.startsWith(baseLabel))
    .map(label => {
      const match = label.match(new RegExp(`^${baseLabel}(\\d+)?$`));
      return match ? (match[1] ? parseInt(match[1]) : 1) : 0;
    })
    .filter(num => num > 0);

  // 既存のラベルがない場合はベースラベルをそのまま返す
  if (existingNumbers.length === 0) {
    return baseLabel;
  }

  // 最大の番号を取得し、その次の番号を付与
  const maxNumber = Math.max(...existingNumbers);
  return `${baseLabel}${maxNumber + 1}`;
}

function getDefaultDetail(type: string): ReqQpPropertyDetail {
  switch (type) {
    case 'textField':
      return {
        textField: {
          defaultValue: '',
          isRequired: false,
          maxLength: 100,
          placeholder: '',
          helpText: '',
        }
      };
    case 'textArea':
      return {
        textArea: {
          defaultValue: '',
          isRequired: false,
          maxLength: 10000,
          placeholder: '',
          helpText: '',
          rows: 5
        }
      };
    case 'textAreaWithToolbar':
      return {
        textAreaWithToolbar: {
          defaultValue: '',
          isRequired: false,
          maxLength: 10000,
          placeholder: '',
          helpText: '',
          rows: 5,
        },
      };
    case 'checkBox':
      return {
        checkBox: {
          isRequired: false,
          helpText: '',
          options: [
            {
              value: 'おまかせ',
              label: 'おまかせ',
              helpText: '',
              isDefault: true,
            },
            {
              value: '選択肢1',
              label: '選択肢1',
              helpText: '',
              isDefault: false,
            },
            {
              value: '選択肢2',
              label: '選択肢2',
              helpText: '',
              isDefault: false,
            },
          ],
          isInline: false,
        },
      };
    case 'radioButton':
      return {
        radioButton: {
          isRequired: false,
          helpText: '',
          options: [
            {
              value: 'おまかせ',
              label: 'おまかせ',
              helpText: '',
              isDefault: true,
            },
            {
              value: '選択肢1',
              label: '選択肢1',
              helpText: '',
              isDefault: false,
            },
            {
              value: '選択肢2',
              label: '選択肢2',
              helpText: '',
              isDefault: false,
            },
          ],
          isInline: false,
        },
      };
    case 'selectBox':
      return {
        selectBox: {
          isRequired: false,
          helpText: '',
          options: [
            {
              value: 'おまかせ',
              label: 'おまかせ',
              helpText: '',
              isDefault: true,
            },
            {
              value: '選択肢1',
              label: '選択肢1',
              helpText: '',
              isDefault: false,
            },
            {
              value: '選択肢2',
              label: '選択肢2',
              helpText: '',
              isDefault: false,
            },
          ],
        },
      };
    case 'singleCheckBox':
      return {
        singleCheckBox: {
          isRequired: false,
          helpText: '',
          defaultValue: false,
        },
      };
    case 'selectAiModels':
      return {
        selectAiModels: {
          isRequired: false,
          helpText: '',
          defaultModels: []
        }
      };
    case 'toggleAdvancedSetting':
      return {
        toggleAdvancedSetting: {}
      };
    default:
      return {};
  }
}

export default QpEditStep2AddItemModal;
