import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ReqUpdateQuotaCustomization, SvSubscriptionWithFull } from '../subscription.type';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from '@/lib/zod';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { WaTextField } from '@/components/ui/WaTextField';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { getPlanTypeLabel } from '@/components/features/subscription/subscription.constant';

/** Vault有効化設定 */
enum VaultEnabledLabel {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
  UNDEFINED = "UNDEFINED"
}

const vaultEnabledLabelToBoolean = (ve: string): boolean | null => {
  switch (ve) {
    case VaultEnabledLabel.ENABLED:
      return true
    case VaultEnabledLabel.DISABLED:
      return false
    default:
      return null
  }
}

const vaultEnabledBooleanToLabel = (ve: boolean | null): VaultEnabledLabel => {
  switch (ve) {
    case true:
      return VaultEnabledLabel.ENABLED
    case false:
      return VaultEnabledLabel.DISABLED
    default:
      return VaultEnabledLabel.UNDEFINED
  }
}

// ReqUpdateQuotaCustomization の型を拡張した新しい型
interface ReqUpdateQuotaCustomizationForm extends ReqUpdateQuotaCustomization {
  vaultEnabledLabel: VaultEnabledLabel; // vaultEnabled プロパティの型を変更
}


const schema = z.object({
  maxAiGenerateCount: z.union([
    z.nullable(z.string()),
    z.number().int().min(0)
  ]),
  maxInputCharsLength: z.union([
    z.nullable(z.string()),
    z.number().int().min(0)
  ]),
  vaultEnabledLabel: z.enum([
    VaultEnabledLabel.ENABLED,
    VaultEnabledLabel.DISABLED,
    VaultEnabledLabel.UNDEFINED,
  ]),
}).superRefine((data, ctx) => {
  if (data.maxAiGenerateCount !== null && typeof data.maxAiGenerateCount === 'string') {
    if (data.maxAiGenerateCount !== '' && (!Number.isInteger(Number(data.maxAiGenerateCount)) || Number(data.maxAiGenerateCount) < 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        // t:空欄または 0 以上の整数値を入力してください
        message: i18next.t('subscription:quotaCustomization.inputValidation'),
        path: ['maxAiGenerateCount'],
      });
    }
  }
  if (data.maxInputCharsLength !== null && typeof data.maxInputCharsLength === 'string') {
    if (data.maxInputCharsLength !== '' && (!Number.isInteger(Number(data.maxInputCharsLength)) || Number(data.maxInputCharsLength) < 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        // t:空欄または 0 以上の整数値を入力してください
        message: i18next.t('subscription:quotaCustomization.inputValidation'),
        path: ['maxInputCharsLength'],
      });
    }
  }
});

interface Props {
  open: boolean;
  handleClose: () => void;
  onSubmit: (data: ReqUpdateQuotaCustomization) => void;
  subscription: SvSubscriptionWithFull;
}

const SvQuotaCustomizationFormModal: React.FC<Props> = ({
  open,
  handleClose,
  onSubmit,
  subscription,
}) => {
  const { t } = useTranslation();
  const team = useSelector((state: RootState) => state.supervisor.team.currentTeam); // TODO： subscription の切り替えが必要

  const { register, handleSubmit, reset, formState: { errors } } = useForm<ReqUpdateQuotaCustomizationForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      maxAiGenerateCount: subscription.quotaCustomization.maxAiGenerateCount,
      maxInputCharsLength: subscription.quotaCustomization.maxInputCharsLength,
      vaultEnabledLabel: vaultEnabledBooleanToLabel(subscription.quotaCustomization.vaultEnabled)
    },
  });

  // モーダルを開くたびにフォームの値をリセット
  useEffect(() => {
    if (open) {
      reset({
        maxAiGenerateCount: subscription.quotaCustomization.maxAiGenerateCount,
        maxInputCharsLength: subscription.quotaCustomization.maxInputCharsLength,
        vaultEnabledLabel: vaultEnabledBooleanToLabel(subscription.quotaCustomization.vaultEnabled)
      })
    }
  }, [open])

  if (!(team && subscription)) {
    return null;
  }

  const onFormSubmit = async (data: ReqUpdateQuotaCustomizationForm) => {
    onSubmit({
      maxAiGenerateCount: data.maxAiGenerateCount ? Number(data.maxAiGenerateCount) : null,
      maxInputCharsLength: data.maxInputCharsLength ? Number(data.maxInputCharsLength) : null,
      vaultEnabled: vaultEnabledLabelToBoolean(data.vaultEnabledLabel)
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      {/* t:プランのカスタマイズ */}
      <DialogTitle>{t("subscription:quotaCustomization.title")}</DialogTitle>
      <DialogContent>
        <TableContainer sx={{ mb: 5 }}>
          <Typography variant="h6" gutterBottom>
              {/* t:現在の内容 */}
              {t("subscription:quotaCustomization.currentContent")}
          </Typography>
          <Table style={{ width: 600 }}>
            <TableHead>
              <TableRow>
                {/* t:プラン */}
                <TableCell>{t("subscription:currentSubscription.plan")}</TableCell>
                {/* t:期間 */}
                <TableCell>{t("subscription:currentSubscription.currentPeriod")}</TableCell>
                {/* t:ライセンス数 */}
                <TableCell>{t("subscription:currentSubscription.licenseCount")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {getPlanTypeLabel(t, subscription.plan.type)}
                  {!!team.scheduledSubscription && (
                    <Typography variant="caption" color="error" component="span">
                      {/* t:(変更予約あり) */}
                      &nbsp;&nbsp;{t("subscription:subscriptionDetail.changeScheduledNote")}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  {team.currentSubscription.latestContractTerm[0]} 〜 {team.currentSubscription.latestContractTerm[1]}
                </TableCell>
                <TableCell>
                  {subscription.currentLicense?.amount}
                  {!!subscription.scheduledLicense && (
                    <Typography variant="caption" color="error" component="span">
                      {/* t:(変更予約あり) */}
                      &nbsp;&nbsp;{t("subscription:subscriptionDetail.changeScheduledNote")}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Typography variant="h6" gutterBottom>
              {/* t:変更内容 */}
              {t("subscription:quotaCustomization.changeContent")}
          </Typography>
          <FormControl fullWidth margin="dense" variant="outlined" error={Boolean(errors.maxAiGenerateCount)}>
            <WaTextField
              type="text" // number型 では 未入力を受け付けられないので text 型
              // t:最大AI生成回数
              label={t("subscription:quotaCustomization.maxAiGenerateCount")}
              {...register('maxAiGenerateCount')}
              error={!!errors.maxAiGenerateCount}
              helperText={errors.maxAiGenerateCount?.message}
            />
          </FormControl>
          <Typography variant='caption' gutterBottom>
            {/* ※ 未入力の場合プランの初期値（{{maxAiGenerateCountDefaultValue}}）が適用されます。 */}
            {t("subscription:quotaCustomization.defaultValueNote", {defaultValue: subscription.plan.quotaTemplate.maxAiGenerateCount || t("subscription:quotaCustomization.noLimit")})}
          </Typography>
          <FormControl fullWidth margin="dense" variant="outlined" error={Boolean(errors.maxInputCharsLength)}>
            <WaTextField
              type="text"
              // t:最大入力文字数
              label={t("subscription:quotaCustomization.maxInputCharsLength")}
              {...register('maxInputCharsLength')}
              error={!!errors.maxInputCharsLength}
              helperText={errors.maxInputCharsLength?.message}
            />
          </FormControl>
          <Typography variant='caption' gutterBottom>
            {/* ※ 未入力の場合プランの初期値（{{maxAiGenerateCountDefaultValue}}）が適用されます。 */}
            {t("subscription:quotaCustomization.defaultValueNote", {defaultValue: subscription.plan.quotaTemplate.maxInputCharsLength || t("subscription:quotaCustomization.noLimit")})}
          </Typography>
          <FormControl fullWidth margin="dense" variant="outlined" error={Boolean(errors.vaultEnabledLabel)}>
            {/* t:Vault連携機能 */}
            <InputLabel>{t("subscription:quotaCustomization.vaultEnabled")}</InputLabel>
            <Select
              // t:Vault連携機能
              label={t("subscription:quotaCustomization.vaultEnabled")}
              defaultValue={vaultEnabledBooleanToLabel(subscription.quotaCustomization.vaultEnabled)}
              {...register('vaultEnabledLabel')}
              error={!!errors.vaultEnabledLabel}
            >
              {/* t:有効 */}
              <MenuItem value={VaultEnabledLabel.ENABLED}>{t("subscription:quotaCustomization.vaultEnabledOptions.enabled")}</MenuItem>
              {/* t:無効 */}
              <MenuItem value={VaultEnabledLabel.DISABLED}>{t("subscription:quotaCustomization.vaultEnabledOptions.disabled")}</MenuItem>
              {/* t:指定なし */}
              <MenuItem value={VaultEnabledLabel.UNDEFINED}>
                {t("subscription:quotaCustomization.vaultEnabledOptions.undefined")}（{vaultEnabledBooleanToLabel(subscription.plan.quotaTemplate.vaultEnabled)}）
              </MenuItem>
            </Select>
          </FormControl>
          <DialogActions>
            {/* t:キャンセル */}
            <Button onClick={handleClose}>{t("common:button.cancel")}</Button>
            {/* t:保存 */}
            <Button type="submit" variant="contained" color="primary">
              {t("common:button.save")}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SvQuotaCustomizationFormModal;
