import { useEffect, useState } from 'react';
import { Box, CircularProgress, Paper, Typography, Button } from '@mui/material';
import { QpEditStep1Data, ReqQpPropertyEdit } from '../qpEditForm.type';
import apiAxios from '@/lib/axios';
import { QpAuthorityType } from '../../quickPrompt/quickPrompt.constant';
import QpFormPresenter from '../../quickPrompt/presenters/QpFormPresenter';
import { QuickPromptWithVersion } from '../../quickPrompt/quickPrompt.type';
import { captureException } from '@sentry/react';

interface Props {
  step1Data: QpEditStep1Data;
  properties: ReqQpPropertyEdit[];
}

function QpEditStep2PreviewContainer({ step1Data, properties }: Props) {
  const [loading, setLoading] = useState(false);
  const [formSchema, setFormSchema] = useState<any>(null);
  const [uiSchema, setUiSchema] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  const fetchPreview = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiAxios.post('/quick-prompts/preview-form', {
        description: step1Data.description,
        properties,
      });
      setFormSchema(response.data.formSchema);
      setUiSchema(response.data.uiSchema);
    } catch (error) {
      console.error(error);
      captureException(error);
      setError("プレビューの取得に失敗しました。もう一度お試しください。");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPreview();
  }, [properties]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight={200}>
        <Typography variant="body1" color="error" gutterBottom>
          {error}
        </Typography>
        <Button variant="contained" color="primary" onClick={fetchPreview}>
          再試行
        </Button>
      </Box>
    );
  }

  if (!formSchema || !uiSchema) {
    return null;
  }

  const dummyQp: QuickPromptWithVersion = {
    id: '',
    name: step1Data.name || "",
    description: step1Data.description,
    shortName: step1Data.shortName,
    shortDescription: step1Data.shortDescription,
    icon: step1Data.icon,
    createdAt: '',
    updatedAt: '',
    quickPromptSetId: '',
    authorityType: QpAuthorityType.USER,
    isOfficial: false,
    isShared: false,
    latestVersion: {
      id: '',
      formSchema,
      uiSchema,
      promptTemplate: '',
      createdAt: '',
    },
  };

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
      <QpFormPresenter
        loading={false}
        qp={dummyQp}
        formData={{}}
        initialFile={undefined}
        selectedFile={undefined}
        onSelectFile={() => {}}
        onGenerating={false}
        optionOpened={false}
        showTrySend={false}
        hideSendButton={true}
        handleFormChange={() => {}}
        handleSendMessage={() => {}}
      />
    </Paper>
  );
}

export default QpEditStep2PreviewContainer;
