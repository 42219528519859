import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import { QpCheckBoxDetail, QpPropertyType, QpRadioButtonDetail, QpSelectBoxDetail, QpTextAreaDetail, QpTextAreaWithToolbarDetail, QpTextFieldDetail } from '../../quickPrompt/quickPrompt.type';
import QpSingleCheckboxSettingFields from '../components/settingFields/QpSingleCheckboxSettingFields';
import QpTextFieldSettingFields from '../components/settingFields/QpTextFieldSettingFields';
import QpTextAreaSettingFields from '../components/settingFields/QpTextAreaSettingFields';
import QpCheckBoxSettingFields from '../components/settingFields/QpCheckBoxSettingFields';
import QpRadioButtonSettingFields from '../components/settingFields/QpRadioButtonSettingFields';
import QpSelectBoxSettingFields from '../components/settingFields/QpSelectBoxSettingFields';
import { ReqQpPropertyEdit } from '../qpEditForm.type';
import QpSelectAiModelsSettingFields from '../components/settingFields/QpSelectAiModelsSettingFields';
import QpToggleAdvanceSettingFields from '../components/settingFields/QpToggleAdvanceSettingFields';
import { generatePropertyKey } from '../qpEditForm.utils';
import QpTextAreaWithToolbarSettingFields from '../components/settingFields/QpTextAreaWithToolbarSettingFields';

interface SettingsModalProps {
  open: boolean;
  defaultData: ReqQpPropertyEdit;
  allProperties: ReqQpPropertyEdit[];
  onClose: () => void;
  onSave: (data: ReqQpPropertyEdit) => void;
}

function QpEditStep2PropertySettingsModalContainer({
  open, defaultData, allProperties, onClose, onSave
}: SettingsModalProps) {
  const [data, setData] = useState<ReqQpPropertyEdit>(defaultData);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // バリデーション通過後に実行される

    // 項目名からキーを作成
    const key = generatePropertyKey(data.label)

    // キーが他の項目と重複している場合はエラーを表示し、重複している項目名を表示
    const duplicateProperty = allProperties.find(p => p.key === key && p.id !== data.id);
    if (duplicateProperty) {
      alert(
        data.label === duplicateProperty.label ?
        (
          "項目名が他の項目と重複しています。\n" +
          "各項目には違う名前を設定するようお願いいたします。"
        ) : (
          "項目名が他の項目と類似しています。\n" +
          "処理の都合、記号や空白以外の部分が同じ項目名は設定できません。\n " +
          "以下のどちらかの項目名を変更するようお願いいたします。\n\n" +
          `現在の項目: ${data.label}\n` +
          `類似の項目: ${duplicateProperty.label}`
        )
      );
      return;
    }

    onSave({...data, key});
    onClose();
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const renderSettingsForm = () => {
    if (data.type == QpPropertyType.TEXT_FIELD && data.detail.textField) {
      return <QpTextFieldSettingFields
        property={data}
        allProperties={allProperties}
        detail={data.detail.textField}
        onChange={(textDetail: QpTextFieldDetail) => {
          setData(prev => ({
            ...prev,
            detail: {
              textField: textDetail,
            },
          }));
        }}
      />;
    }
    if (data.type == QpPropertyType.TEXT_AREA && data.detail.textArea) {
      return <QpTextAreaSettingFields
        property={data}
        allProperties={allProperties}
        detail={data.detail.textArea}
        onChange={(textDetail: QpTextAreaDetail) => {
          setData(prev => ({
            ...prev,
            detail: {
              textArea: textDetail,
            },
          }));
        }}
      />;
    }
    if (data.type == QpPropertyType.TEXT_AREA_WITH_TOOLBAR && data.detail.textAreaWithToolbar) {
      return <QpTextAreaWithToolbarSettingFields
        property={data}
        allProperties={allProperties}
        detail={data.detail.textAreaWithToolbar}
        onChange={(textDetail: QpTextAreaWithToolbarDetail) => {
          setData(prev => ({
            ...prev,
            detail: {
              textAreaWithToolbar: textDetail,
            },
          }));
        }}
      />;
    }
    if (data.type == QpPropertyType.CHECK_BOX && data.detail.checkBox) {
      return <QpCheckBoxSettingFields
        property={data}
        allProperties={allProperties}
        detail={data.detail.checkBox}
        onChange={(choiceDetail: QpCheckBoxDetail) => {
          setData(prev => ({
            ...prev,
            detail: {
              checkBox: choiceDetail,
            },
          }));
        }}
      />;
    }
    if (data.type == QpPropertyType.RADIO_BUTTON && data.detail.radioButton) {
      return <QpRadioButtonSettingFields
        property={data}
        allProperties={allProperties}
        detail={data.detail.radioButton}
        onChange={(choiceDetail: QpRadioButtonDetail) => {
          setData(prev => ({
            ...prev,
            detail: {
              radioButton: choiceDetail,
            },
          }));
        }}
      />;
    }
    if (data.type == QpPropertyType.SELECT_BOX && data.detail.selectBox) {
      return <QpSelectBoxSettingFields
        property={data}
        allProperties={allProperties}
        detail={data.detail.selectBox}
        onChange={(textDetail: QpSelectBoxDetail) => {
          setData(prev => ({
            ...prev,
            detail: {
              selectBox: textDetail,
            },
          }));
        }}
      />;
    }
    if (data.type == QpPropertyType.SINGLE_CHECK_BOX && data.detail.singleCheckBox) {
      return <QpSingleCheckboxSettingFields
        property={data}
        allProperties={allProperties}
        detail={data.detail.singleCheckBox}
        onChange={(textDetail) => {
          setData(prev => ({
            ...prev,
            detail: {
              singleCheckBox: textDetail,
            },
          }));
        }}
      />;
    }
    if (data.type == QpPropertyType.SELECT_AI_MODELS && data.detail.selectAiModels) {
      return <QpSelectAiModelsSettingFields
        property={data}
        allProperties={allProperties}
        detail={data.detail.selectAiModels}
        onChange={(textDetail) => {
          setData(prev => ({
            ...prev,
            detail: {
              selectAiModels: textDetail,
            },
          }));
        }}
      />;
    }
    if (data.type == QpPropertyType.TOGGLE_ADVANCED_SETTING && data.detail.toggleAdvancedSetting) {
      return <QpToggleAdvanceSettingFields
        // property={data}
        // allProperties={allProperties}
        // detail={data.detail.toggleAdvancedSetting}
        // onChange={(detail) => {
        //   setData(prev => ({
        //     ...prev,
        //     detail: {
        //       toggleAdvancedSetting: detail,
        //     },
        //   }));
        // }}
      />;
    }
    return null;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>詳細設定</DialogTitle>
        <DialogContent>
          <TextField
            label="項目名"
            name="label"
            value={data.label || ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
            inputProps={{ maxLength: 100 }}
            helperText="100文字まで"
          />
          {renderSettingsForm()}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
          >
            保存
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default QpEditStep2PropertySettingsModalContainer;
