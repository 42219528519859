import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '@/store';
import { getCurrentMembership } from '../../auth/auth.type';
import { QpAuthorityType } from '../quickPrompt.constant';
import { updateQpManagement, deleteQuickPromptSet } from '../quickPrompt.slice';
import { useToast } from '../../generic/hooks/useToast';
import { useTranslation } from 'react-i18next';
import QpShareModal from '../../quickPromptManagement/containers/QpShareModal';
import QpSetEditModal from '../../quickPromptManagement/containers/QpSetEditModal';
import { QuickPromptSetWithQpList } from '../quickPrompt.type';
import { useConfirmModal } from '../../generic/hooks/useConfirmModal';

interface QpListQpSetMenuContainerProps {
  anchorPosition: { mouseX: number; mouseY: number } | null;
  open: boolean;
  quickPromptSet?: QuickPromptSetWithQpList;
  handleMenuClose: (event: React.MouseEvent<HTMLElement>) => void;
}

const QpListQpSetMenuContainer: React.FC<QpListQpSetMenuContainerProps> = ({
  anchorPosition,
  open,
  quickPromptSet,
  handleMenuClose,
}) => {
  const { showToast } = useToast();
  const { setConfirmModal } = useConfirmModal();
  const dispatch = useDispatch<AppDispatch>();
  const qpManagement = useSelector((state: RootState) => state.quickPrompt.qpManagement);
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const currentMembership = getCurrentMembership(loginUser);
  const [sharedModalOpened, setSharedModalOpened] = useState(false);
  const [editModalOpened, setEditModalOpened] = useState(false);
  const { t } = useTranslation();

  const hasEditorAuth = quickPromptSet?.authorityType !== QpAuthorityType.USER;
  const hasOwnerAuth = quickPromptSet?.authorityType === QpAuthorityType.OWNER;

  const handleEditClick = (event: React.MouseEvent<HTMLElement>) => {
    handleMenuClose(event);
    setEditModalOpened(true);
  };

  const handleSharedModalClosed = () => {
    setSharedModalOpened(false);
  };

  const handleShareClick = (event: React.MouseEvent<HTMLElement>) => {
    handleMenuClose(event);
    setSharedModalOpened(true);
  };

  const handleHideClick = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleMenuClose(event);
    if (quickPromptSet) {
      const newQpManagement = {
        ...qpManagement,
        hideSetIds: [...qpManagement.hideSetIds, quickPromptSet.id],
      };
      await dispatch(updateQpManagement(newQpManagement));
      // t:{{name}}を非表示にしました
      showToast(t(
        'quickPrompt:qpList.messages.hideSuccess',
        {name: quickPromptSet.name}
      ), 'success')
    }
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleMenuClose(event);
    if (quickPromptSet) {
      setConfirmModal(
        // t: カテゴリ削除確認
        t('quickPrompt:qpList.modal.deleteSetConfirmation.title'),
        // t: カテゴリ「{{name}}」を削除します。
        //    またこのカテゴリに所属するクイックプロンプトも併せて削除します。
        //    削除してよろしいですか？
        t('quickPrompt:qpList.modal.deleteSetConfirmation.message', { name: quickPromptSet.name }),
        async () => {
          await dispatch(deleteQuickPromptSet(quickPromptSet.id));
          // t: {{name}}カテゴリを削除しました。
          showToast(t('quickPrompt:qpList.messages.deleteSetSuccess', { name: quickPromptSet.name }), 'success');
        }
      );
    }
  };

  return (
    <>
      <Menu
        open={open}
        onClose={handleMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          anchorPosition ? { top: anchorPosition.mouseY, left: anchorPosition.mouseX } : undefined
        }
        keepMounted
        disableRestoreFocus
      >
        {hasEditorAuth && (
          <MenuItem key="edit" onClick={handleEditClick}>{
            // t:編集
            t('quickPrompt:qpList.actions.edit')
          }</MenuItem>
        )}
        {hasEditorAuth && (
          <MenuItem key="share" onClick={handleShareClick}>{
            // t:共有
            t('quickPrompt:qpList.actions.share')
          }</MenuItem>
        )}
        <MenuItem key="hide" onClick={handleHideClick}>{
            // t:非表示
            t('quickPrompt:qpList.actions.hide')
          }</MenuItem>
        {hasOwnerAuth && (
          <MenuItem
            key="delete"
            onClick={handleDeleteClick}
            sx={{ color: 'error.main', fontWeight: 'bold' }}>{
              // t:削除
              t('quickPrompt:qpList.actions.delete')
          }</MenuItem>
        )}
      </Menu>

      {quickPromptSet && (
        <QpSetEditModal
          open={editModalOpened}
          onClose={() => setEditModalOpened(false)}
          quickPromptSet={quickPromptSet}
        />
      )}

      {quickPromptSet && currentMembership && (
        <QpShareModal
          open={sharedModalOpened}
          onClose={handleSharedModalClosed}
          sharedObject={quickPromptSet}
          team={currentMembership.team}
          userAuthority={quickPromptSet.authorityType}
        />
      )}
    </>
  );
}

export default QpListQpSetMenuContainer;
