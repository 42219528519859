import { WidgetProps } from '@rjsf/utils';
import React, { useState, useEffect } from 'react';
import { FileUpload } from '../../file/file.type';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getCurrentMembership } from '../../auth/auth.type';
import AttachedFileInfo from '../../thread/components/AttachedFileInfo';
import FileUploadButton from '../../thread/components/MessageForm/FIleUploadButton';
import styled from '@emotion/styled';
import { Box, TextField, Toolbar, Typography } from '@mui/material';
import { TeamConfig } from '../../team/team.type';
import { useTheme } from '@mui/material';

interface FormContextType {
  handleFileChange?: (file?: File) => void;
}

interface TextAreaWithFileWidgetOptions {
  initialFile?: FileUpload;
}


export const MessageToolbar = styled(Toolbar)`
  padding: 0;
  min-height: 48px !important;
  justify-content: space-between; /* アイテムを両端に配置 */
`;

export const IconButtons = styled.div`
  display: flex;
  align-items: center;
`;

const TextareaWithToolbar: React.FC<WidgetProps> = (props) => {
  const {
    id,
    label,
    disabled,
    required,
    autofocus,
    onBlur,
    onFocus,
    onChange,
    value,
    placeholder,
    formContext,
    options,
    uiSchema,
    // rawErrors = [],
  } = props;

  const [textValue, setTextValue] = useState<string>(value || '');
  const [selectedFile, setSelectedFile] = useState<File | FileUpload | undefined>(undefined);
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const membership = getCurrentMembership(loginUser)
  const teamConfig = membership?.team?.config

  useEffect(() => {
    if (options && (options as TextAreaWithFileWidgetOptions).initialFile) {
      const initialFile = (options as TextAreaWithFileWidgetOptions).initialFile;
      setSelectedFile(initialFile);
    }
  }, []);

  if (!(membership && teamConfig)) {
    return null;
  }

  const shouldHideToolbar = !teamConfig.isFileAttachmentEnabled;

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setTextValue(newValue);
    onChange(newValue);
  };

  const selectFileHandler = (file: File | undefined) => {
    setSelectedFile(file);

    if (
      formContext &&
      typeof (formContext as FormContextType).handleFileChange === 'function'
    ) {
      (formContext as FormContextType)?.handleFileChange?.(file);
    }
  };

  const removeFileHandler = () => {
    selectFileHandler(undefined);
  };

  const focusHandler = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    if (onFocus) {
      onFocus(id, event.target.value);
    }
  }

  const blurHandler = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    if (onBlur) {
      onBlur(id, event.target.value);
    }
  }

  return (
    <TextareaWithToolbarPresenter
      id={id}
      label={label}
      shouldHideToolbar={shouldHideToolbar}
      textValue={textValue}
      required={required}
      disabled={disabled}
      autofocus={autofocus}
      rows={uiSchema?.['ui:rows']}
      placeholder={placeholder}
      handleTextChange={handleTextChange}
      blurHandler={blurHandler}
      focusHandler={focusHandler}
      selectedFile={selectedFile}
      selectFileHandler={selectFileHandler}
      removeFileHandler={removeFileHandler}
      teamConfig={teamConfig}
    />
  );
}

interface TextareaWithToolbarPresenterProps {
  id: string;
  label: string;
  textValue: string;
  shouldHideToolbar?: boolean;
  rows?: number;
  required?: boolean;
  disabled?: boolean;
  autofocus?: boolean;
  placeholder?: string;
  handleTextChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  blurHandler?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  focusHandler?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  selectedFile?: File | FileUpload | undefined;
  selectFileHandler?: (file: File | undefined) => void;
  removeFileHandler?: () => void;
  teamConfig: TeamConfig;
}

export const TextareaWithToolbarPresenter: React.FC<TextareaWithToolbarPresenterProps> = ({
  id,
  label,
  textValue,
  teamConfig,
  placeholder,
  selectedFile,
  rows,
  shouldHideToolbar = false,
  required = false,
  disabled = false,
  autofocus = false,
  handleTextChange = () => {},
  blurHandler = () => {},
  focusHandler = () => {},
  selectFileHandler = () => {},
  removeFileHandler = () => {},
}) => {
  const theme = useTheme();

  const mainTextArea = (
    <TextField
      id={id}
      label={shouldHideToolbar ? label : null}
      fullWidth
      multiline
      rows={rows ? rows : undefined}
      minRows={rows ? undefined : 4}
      maxRows={rows ? undefined : 10}
      value={textValue}
      required={shouldHideToolbar ? required : false}
      disabled={disabled}
      autoFocus={autofocus}
      placeholder={placeholder}
      onChange={handleTextChange}
      onBlur={blurHandler}
      onFocus={focusHandler}
      sx={{
        resize: 'vertical',
        '& .MuiInputBase-input': {
          marginBottom: selectedFile ? '70px' : 'inherit',
        },
      }}
    />
  );

  if (shouldHideToolbar) {
    return mainTextArea;
  }

  return (
    <Box>
      <Typography variant="subtitle1" color="#555">
        {label}
        {required && <span style={{ color:  theme.palette.error.main }}> *</span>}
      </Typography>

      <MessageToolbar disableGutters>
        <IconButtons>
          {teamConfig.isFileAttachmentEnabled && (
            <FileUploadButton
              onFileSelect={selectFileHandler}
              currentFile={selectedFile}
            />
          )}
        </IconButtons>
      </MessageToolbar>

      <Box sx={{ position: 'relative', width: "100%" }}>
        {mainTextArea}
        {selectedFile && (
          <Box
            sx={{
              position: 'absolute',
              bottom: "15px",
              left: "15px",
              margin: "0 auto",
            }}
          >
            <AttachedFileInfo
              file={selectedFile}
              onRemove={removeFileHandler}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TextareaWithToolbar;
