import { QpPropertyType } from "../quickPrompt/quickPrompt.type";
import { errorRegexPatterns, errorTranslationMap } from "./qpEditForm.contant";
import { ReqQpPropertyEdit } from "./qpEditForm.type";

export function generatePropertyKey(
    propertyLabel: string
): string {
    // Unicode の記号クラス \p{P} を使用して、
    // 多言語の記号（「、」「。」「？」など）と空白を '_' に置換
    return propertyLabel.replace(/[\s\p{P}]+/gu, '_');
}

/**
 * 指定されたプロパティが「詳細設定」のプロパティかどうかを判定する
 *
 * 具体的には詳細設定のon/offの項目より下かどうかで判定する
 */
export function isAdvanceSettingProperty(
  property: ReqQpPropertyEdit,
  allProperties: ReqQpPropertyEdit[],
): boolean {
  const toggleAdvanceSettingIndex = allProperties.findIndex(
    p => p.type ===  QpPropertyType.TOGGLE_ADVANCED_SETTING
  );
  const currentPropertyIndex = allProperties.findIndex(p => p.id === property.id);
  return (
    toggleAdvanceSettingIndex >= 0 &&
    toggleAdvanceSettingIndex < currentPropertyIndex
  );
}

/** jinja2テンプレートのエラーメッセージを日本語化する関数 */
export function translateJinjaErrorMessage(englishMessage: string): string {
  console.debug("translateJinjaErrorMessage", englishMessage);
  // 完全一致チェック
  if (errorTranslationMap[englishMessage]) {
    return errorTranslationMap[englishMessage];
  }

  // 部分一致チェック - 全ての一致を変換
  let translatedMessage = englishMessage;
  for (const key in errorTranslationMap) {
    if (translatedMessage.includes(key)) {
      translatedMessage = translatedMessage.replace(key, errorTranslationMap[key]);
    }
  }

  // 正規表現パターンマッチ
  for (const {pattern, replace} of errorRegexPatterns) {
    const match = translatedMessage.match(pattern);
    if (match) {
      // `$1`, `$2`... といった置換用変数は、`replace`文字列中で使えるように
      // JavaScriptのreplaceで対応
      // 例: "予期しない '$1' が..." => "予期しない 'abc' が..."
      // 以下のように、カスタム関数でmatch結果をreplace用に適用する
      translatedMessage = translatedMessage.replace(pattern, () => {
        return replace.replace(/\$(\d+)/g, (_, index) => match[Number(index)]);
      });
    }
  }

  // 該当する翻訳がなければ英語のまま返す
  return translatedMessage;
}
