import { QpChoiceOptions, QpManagement, QuickPromptSetWithQpList, QuickPromptWithVersion, ReqQpProperty } from "./quickPrompt.type";

export const getTextareaWithToolbarKey = (qp: QuickPromptWithVersion): string | undefined => {
  let textareaWithToolbarKey = '';
  const targetVersion = qp.targetVersion || qp.latestVersion;
  const uiSchema: Record<string, any> = targetVersion.uiSchema;
  for (const key in uiSchema) {
    const schema = uiSchema[key];
    if (schema['ui:widget'] === 'textareaWithToolbar') {
      textareaWithToolbarKey = key;
      break;
    }
  }
  return textareaWithToolbarKey || undefined;
};

export const getUiSchema = (qp: QuickPromptWithVersion,  initialFile: any): Record<string, any> => {
  const key = getTextareaWithToolbarKey(qp);
  const targetVersion = qp.targetVersion || qp.latestVersion;
  const uiSchema: Record<string, any> = targetVersion.uiSchema;

  if (!key) {
    return uiSchema;
  }

  const updatedUiSchema: Record<string, any> = { ...uiSchema };
  const schema = updatedUiSchema[key];
  if (schema) {
    updatedUiSchema[key as keyof typeof updatedUiSchema] = {
      ...schema,
      'ui:options': {
        ...(schema['ui:options'] as any),
        initialFile: initialFile,
      },
    };
  }
  return updatedUiSchema;
};

export const getFormSchema = (qp: QuickPromptWithVersion): Record<string, any> => {
  const key = getTextareaWithToolbarKey(qp);
  const targetVersion = qp.targetVersion || qp.latestVersion;
  const formSchema: Record<string, any> = targetVersion.formSchema;

  if (!key) {
    return formSchema;
  }

  const updatedFormSchema: Record<string, any> = JSON.parse(JSON.stringify(formSchema));

  return updatedFormSchema;
}

export const getFormSchemaTitle = (qp: QuickPromptWithVersion, key: string): string => {
  const targetVersion = qp.targetVersion || qp.latestVersion;
  const formSchema: Record<string, any> = targetVersion.formSchema;
  const properties = formSchema['properties'];

  return properties[key]?.title;
}


export const getQpOptions = (property: ReqQpProperty): QpChoiceOptions[] => {
  if (property.type === 'selectBox') {
    return property.detail.selectBox?.options || [];
  }
  if (property.type === 'checkBox') {
    return property.detail.checkBox?.options || [];
  }
  if (property.type === 'radioButton') {
    return property.detail.radioButton?.options || [];
  }
  return [];
}



export const sortQpSetByQpManagementOrder = (
  qpSets: QuickPromptSetWithQpList[],
  qpManagement: QpManagement
): QuickPromptSetWithQpList[] => {
  return qpSets.sort((a, b) => {
    const indexA = qpManagement.orderedSetIds.indexOf(a.id);
    const indexB = qpManagement.orderedSetIds.indexOf(b.id);
    if (indexA === -1 && indexB === -1) {
      return 0; // どちらもorderedSetIdsに存在しない場合、元の順序を維持
    }
    if (indexA === -1) {
      return 1; // aがorderedSetIdsに存在しない場合、bを優先
    }
    if (indexB === -1) {
      return -1; // bがorderedSetIdsに存在しない場合、aを優先
    }
    return indexA - indexB; // 両方ともorderedSetIdsに存在する場合、indexで比較
  });
}

