import { createTheme, Theme } from '@mui/material/styles';

// カスタムテーマの定義
export const mainTheme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',  // プライマリカラー
    },
    secondary: {
      main: '#dadce0',  // セカンダリカラー
    },
    error: {
      main: '#ff1744',  // エラーカラー
      contrastText: '#fff',  // エラーテキストカラー
    },
    background: {
      default: '#f0f4f9',  // デフォルトの背景色
      paper: '#fff', // ペーパーコンポーネントの背景色
    },
    text: {
      primary: 'rgb(31, 31, 31)',  // メインテキストカラー
      secondary: '#555', // セカンダリテキストカラー
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // フォントファミリー
    h1: {
      fontSize: '2.2rem',
    },
    body1: {
      fontSize: '1rem',
    },
  },
  spacing: 8, // デフォルトのスペーシング単位（8px）
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#f0f4f9', // ヘッダーの背景色を設定
          color: 'rgb(31, 31, 31)',
          boxShadow: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          [theme.breakpoints.up('sm')]: {
            paddingRight: theme.spacing(1),
          },
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '14px', // ツールチップ内のテキストサイズを指定
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#333', // Typographyの基本色を設定
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',  // ボタンのテキストを変換しないように設定
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(0, 0, 0, 0.12)', // 薄いボーダーを追加
          boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)', // より洗練された影を追加
        },
      },
    },
    // 必須マークは赤にする
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#ff1744', // MUIデフォルトテーマの「error」の色
          '&$error': {
            color: '#ff1744',
          },
        },
      },
    },
  },
});

export const supervisorTheme = createTheme(mainTheme, {
  palette: {
    primary: {
      main: '#1976d2',  // ブルー700
    },
    secondary: {
      main: '#90caf9',  // ライトブルー200
    },
    background: {
      default: '#e3f2fd',  // ライトブルーの薄い色
    },
    text: {
      primary: 'rgb(51, 51, 51)',  // 濃い目のグレー
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#2196f3', // ヘッダーの背景色をブルー500に設定
          color: '#fff',  // ヘッダーのテキストを白色に
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: 'rgb(51, 51, 51)', // Typographyの基本色を設定
        },
      },
    },
  },
});

export default mainTheme;
