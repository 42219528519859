import React from 'react';
import { Box, Typography, Grid, Fade } from '@mui/material';
import { RecommendedQp } from '../quickPrompt.type';
import * as Icons from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import QpTutorialContainer from '../containers/QpTutorialContainer';
import { useTranslation } from 'react-i18next';
import ArrowTooltip from '../../generic/containers/ArrowTooltip';

interface Props {
  isTutorialDone: boolean,
  recommendedQpList: RecommendedQp[];
}

const RecommendQpListPresenter: React.FC<Props> = ({
  isTutorialDone,
  recommendedQpList,
}) => {
  const { t } = useTranslation();

  if (recommendedQpList.length == 0) {
    return <></>
  }

  const DynamicIcon = ({ iconName }: { iconName?: string }) => {
    const IconComponent = iconName && iconName in Icons ?
      Icons[iconName as keyof typeof Icons] :
      Icons.InfoOutlined
    ;
    return <IconComponent sx={{ fontSize: 40, marginBottom: 1 }}/>;
  };


  return (<>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{
          width: '100%',
          maxWidth: "640px",
          mt: 3,
          mb: 5,
      }}>
        <QpTutorialContainer />
      </Box>

      <Typography variant="h5" gutterBottom mb={5}>
        {/* t:おすすめクイックプロンプト */}
        {t("quickPrompt:recommendedList.title")}
      </Typography>

      <Grid container spacing={2} justifyContent="center" sx={{
        maxWidth: "640px",
        width: "100%",
      }}>
        {recommendedQpList.map((rqp) => (
          <Grid item key={rqp.qp.id} xs={6} sm={4} md={4} sx={{ display: 'flex' }}>
            <ArrowTooltip title={rqp.description}>
              <Box
                component={RouterLink}
                to={`/quick-prompts/${rqp.qp.id}/threads/new?recommend=1`}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: 1,
                  borderRadius: 2,
                  border: `2px solid ${rqp.color}99`,
                  textDecoration: 'none',
                  color: rqp.color,
                  width: '100%',
                  flexGrow: 1,
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    backgroundColor: `${rqp.color}10`,
                    transform: 'translateY(-5px)',
                    boxShadow: `0 1px 5px 0 ${rqp.color}30`,
                  },
                  '& .title': {
                    fontSize: '18px',
                  },
                }}
              >
                <DynamicIcon iconName={rqp.qp.icon}/>
                <Typography className="title" sx={{ marginBottom: 1, fontSize: "18px", textAlign: "center" }}>
                  {rqp.qp.name}
                </Typography>
              </Box>
            </ArrowTooltip>
          </Grid>
        ))}
      </Grid>
      <Fade in={isTutorialDone}>
        <Typography variant="body2" mt={4} color="textSecondary">
          {/* t:左サイドバーには他にも多くのクイックプロンプトがありますので、ぜひご覧ください。 */}
          {t("quickPrompt:tutorial.afterCompletion")}
        </Typography>
      </Fade>
    </Box>
  </>);
};

export default RecommendQpListPresenter;
