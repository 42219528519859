// IconPicker.tsx
import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, TextField, Typography, IconButton, useMediaQuery } from '@mui/material';
import * as Icons from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { FixedSizeGrid as Grid } from 'react-window';
import { useTheme } from '@mui/material/styles';
import synonyms from './data/synonyms'

interface IconPickerProps {
  value: string;
  onChange: (iconName: string) => void;
}

const IconPicker: React.FC<IconPickerProps> = ({ value, onChange }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedIcon, setSelectedIcon] = useState<string>(value || '');
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [iconList, setIconList] = useState<string[]>([]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreenOrLarger = useMediaQuery(theme.breakpoints.up('md'));

  // アイコンのキャッシュを作成
  const iconCache = useMemo(() => {
    // Outlinedアイコンのみを取得
    const iconNames = Object.keys(Icons)
      .filter(name => name !== 'default' && name.endsWith('Outlined'));
    return iconNames;
  }, []);

  useEffect(() => {
    setIconList(iconCache);
  }, [iconCache]);

  const handleOpen = () => {
    setOpen(true);
    // モーダルを開くときに検索キーワードをリセット
    setSearchKeyword('');
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleIconClick = (iconName: string) => {
    setSelectedIcon(iconName);
    onChange(iconName);
    setOpen(false);
  };

  // 検索キーワードに基づいてアイコンをフィルタリングし、関連度でソート
  const getFilteredIcons = () => {
    if (!searchKeyword) return iconList;

    const keyword = searchKeyword.toLowerCase();

    return iconList
      .map((iconNameWithOutlined) => {
        const iconName = iconNameWithOutlined.replace('Outlined', '');
        const name = iconName.toLowerCase();
        const synonymsText = synonyms[iconName] ? synonyms[iconName].toLowerCase() : '';

        let relevance = 0;
        if (name === keyword) {
          relevance = 5;
        } else if (name.startsWith(keyword)) {
          relevance = 4;
        } else if (name.includes(keyword)) {
          relevance = 3;
        } else if (synonymsText.includes(keyword)) {
          relevance = 2;
        }

        return {
          iconName: iconNameWithOutlined,
          relevance
        };
      })
      .filter(item => item.relevance > 0)
      .sort((a, b) => b.relevance - a.relevance)
      .map(item => item.iconName);
  };


  const filteredIcons = getFilteredIcons();

  const SelectedIconComponent = selectedIcon ? (Icons as any)[selectedIcon] : null;

  // react-window用のセルサイズ
  const COLUMN_COUNT = isSmallScreen ? 3 : isMediumScreenOrLarger ? 8 : 6;
  const CELL_WIDTH = 100;
  const CELL_HEIGHT = 100;

  const Cell = ({ columnIndex, rowIndex, style, data }: any) => {
    const index = rowIndex * COLUMN_COUNT + columnIndex;
    if (index >= data.length) return null;
    const iconName = data[index];
    const IconComponent = (Icons as any)[iconName];
    return (
      <div
        style={{
          ...style,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start', // 上端に揃える
          cursor: 'pointer',
          padding: 8,
        }}
        onClick={() => handleIconClick(iconName)}
      >
        {IconComponent && <IconComponent fontSize="large" />}
        <Typography variant="caption" align="center" noWrap={false} style={{ wordBreak: 'break-word' }}>
          {iconName.replace('Outlined', '')}
        </Typography>
      </div>
    );
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box mr={5}>
          {selectedIcon ? (
            <Box display="flex" alignItems="center">
              {SelectedIconComponent && (
                <SelectedIconComponent style={{ marginRight: 8 }} />
              )}
              <Typography>{selectedIcon.replace('Outlined', '')}</Typography>
            </Box>
          ) : (
            <Typography>未選択</Typography>
          )}
        </Box>
        <Button variant="outlined" onClick={handleOpen}>
          変更
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          アイコンを選択
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            placeholder="検索キーワードを「英数字」で入力してください"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <Box mt={2}>
            <Grid
              columnCount={COLUMN_COUNT}
              columnWidth={CELL_WIDTH}
              height={400}
              rowCount={Math.ceil(filteredIcons.length / COLUMN_COUNT)}
              rowHeight={CELL_HEIGHT}
              width={COLUMN_COUNT * CELL_WIDTH}
              itemData={filteredIcons}
            >
              {Cell}
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default IconPicker;
