import { QpTextFieldDetail } from "@/components/features/quickPrompt/quickPrompt.type";
import { TextField } from "@mui/material";
import QpInputPropertySettingFieldsBase from "./QpInputPropertySettingFieldsBase";
import { ReqQpPropertyEdit } from "../../qpEditForm.type";

interface Props {
  property: ReqQpPropertyEdit;
  allProperties: ReqQpPropertyEdit[];
  detail: QpTextFieldDetail;
  onChange: (data: QpTextFieldDetail) => void;
  inputRows?: number;
}

function QpTextFieldSettingFields({
  property,
  allProperties,
  detail,
  onChange,
  inputRows = 1,
}: Props) {

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    onChange({
      ...detail,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  return (
    <>
      <QpInputPropertySettingFieldsBase
        property={property}
        allProperties={allProperties}
        detail={detail}
        onChange={(baseData) => onChange({
          ...detail,
          ...baseData,
        })}
      />
      <TextField
        label="プレースホルダー"
        name="placeholder"
        value={detail.placeholder || ''}
        onChange={handleInputChange}
        fullWidth
        multiline={inputRows > 1 ? true : false}
        rows={inputRows}
        margin="normal"
      />
      <TextField
        label="初期値"
        name="defaultValue"
        value={detail.defaultValue || ''}
        onChange={handleInputChange}
        fullWidth
        multiline={inputRows > 1 ? true : false}
        rows={inputRows}
        margin="normal"
      />
    </>
  );
}

export default QpTextFieldSettingFields;
