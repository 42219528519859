
// Start of Selection
import React, { useRef } from 'react';
import { AIMessage, MessageNode } from '../thread.type';
import styled from '@emotion/styled';
import { Avatar, Box, Button, IconButton, Typography } from '@mui/material';
import { AIMessageStatus, MessageType } from '../thread.constant';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { AIModelConsts } from '../../aiModel/aiModel.constant';
import { restoreMask } from '../thread.utils';
import useThreadApi from '../hooks/useThreadApi';
import ThreadPostWordReplacedInfo from './ThreadPostReplacementInfo';
import { displayTimestamp } from '@/lib/utils';
import theme from '@/theme';
import AiGenerateStatus from '../components/AiGenerateStatus';
import { PostInfoWrapper } from './ThreadPost';
import { copyToClipboard, getOuterHtmlFromElement } from '@/common/utils/copy';
import { useToast } from '../../generic/hooks/useToast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomMarkdown from '@/components/ui/CustomMarkdown';
import { useTranslation } from 'react-i18next';

interface Props {
  node: MessageNode;
  aiMessage: AIMessage;
  minimizeHeaderInfo?: boolean;
  scroll?: boolean;
}

const AIPostWrapper = styled.div<{ scroll: boolean }>`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  align-self: flex-start;
  background-color: #fff;
  border: 1px solid #ddd;

  ${props => props.scroll ? 'overflow-y: scroll; height: 100%;' : ''}

  ${theme.breakpoints.up('md')} {
    max-width: min(1000px, 100%);
  }

  pre {
    white-space: pre-wrap;
  }

  .hover-content {
    display: none;
  }
  &:hover .hover-content {
    display: block;
  }
`;

const CustomMarkdownWrapper = styled.div`
  padding: 20px 5px;
`;

const ThreadPostAiMessage: React.FC<Props> = ({node, aiMessage, minimizeHeaderInfo = false, scroll = false}) => {
  const { t } = useTranslation();
  const copyAnchor = useRef<HTMLDivElement>(null);
  const currentThread = useSelector((state: RootState) => state.thread.currentThread);
  const { retryAIMessage } = useThreadApi();
  const { showToast } = useToast();

  if (!currentThread) return <></>;

  const getAvatar = (aiMessage: AIMessage) : string => {
    const aiModel = AIModelConsts.find((model) => model.id === aiMessage.version.aiModel.code);
    return aiModel ? aiModel.avatar : '';
  }

  const copy = () => {
    if (copyAnchor.current) {
      const textHtml = getOuterHtmlFromElement(copyAnchor.current);
      copyToClipboard(
        aiMessage.body,
        textHtml,
        showToast,
      );
    }
  };

  return (
    <AIPostWrapper key={aiMessage.id} scroll={scroll}>
      <PostInfoWrapper>
        <Avatar src={ getAvatar(aiMessage) } />
        <Typography variant="body2" color="textSecondary">
          <b>{aiMessage.version.aiModel.name}</b>
          {!minimizeHeaderInfo && <>&nbsp;&nbsp;{displayTimestamp(node.createdAt)}</>}
        </Typography>
        <AiGenerateStatus aiMsg={aiMessage} hideSuccess={minimizeHeaderInfo}/>
        {/* ワード置換情報 */}
        {(() => {
          if (aiMessage.status !== AIMessageStatus.FINISHED) {
            return <></>
          }

          const replaced = restoreMask(aiMessage.body, currentThread.dlpReplaces)
          if (replaced === aiMessage.body) {
            return <></>
          }

          return (
            <ThreadPostWordReplacedInfo
              type={MessageType.AI}
              body={aiMessage.body}
              minimize={true}
            />
          )
        })()}
        <div style={{ flexGrow: 1 }} />
        <Box className="hover-content">
          <IconButton size="small" onClick={copy}>
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Box>
      </PostInfoWrapper>
      <CustomMarkdownWrapper ref={copyAnchor}
        style={{"display": aiMessage.status == AIMessageStatus.FAILED ? "none" : ""}}>
        <CustomMarkdown markdown={
          aiMessage.status === AIMessageStatus.FINISHED ?
          restoreMask(aiMessage.body, currentThread.dlpReplaces) :
          aiMessage.body
        } />
      </CustomMarkdownWrapper>

      {/* エラー理由の表示 */}
      {(() => {
        if (aiMessage.status !== AIMessageStatus.FAILED) {
          return <></>
        }

        const failure = aiMessage.failures.length > 0 ? aiMessage.failures.reduce((a, b) => a.createdAt > b.createdAt ? a : b) : null;
        const failureCode = failure ? failure.code : 'unknown';
        const failureCanRetry = failure ? failure.canRetry : true;
        const aiModelName = aiMessage.version.aiModel.name;

        // t:リトライを押して再度生成を試みてください。
        let message = <>{t(
          "thread:aiResponse.unknownError",
          {aiModelName}
        )}</>;
        console.log(failureCode);
        switch (failureCode) {
          case "context_length_exceeded":
            {/*
              t:「{{aiModelName}}」に入力できる最大文字数を超過しました。
                このスレッドではこれ以上やりとりを継続できませんので、新たにスレッドを作成してください。
                (これまでのやりとりを要約するなどして、生成AIに伝えてあげるとやり取りを継続しやすいでしょう)
            */}
            message = <>{t(
                "thread:aiResponse.contextLengthExceeded",
                {aiModelName}
            )}</>;
            break;
          case "invalid_content":
            {/*
              t:下記に関するやりとりであると「{{aiModelName}}」が判断したため、回答が中止されました。
                お手数おかけしますが、新規スレッドにて入力内容を調整しやり直してください。
            */}
            message = <>
              {t("thread:aiResponse.invalidContent", {aiModelName})}
              <Typography variant="caption" component="span" color="error" style={{ whiteSpace: 'pre-line' }}>
                {/* t:
                    ・ 安全性に問題がある内容 (政治的、宗教的、性的、差別的な内容など)
                    ・ 著作権を侵害する内容
                    ・ 他者のプライバシーを侵害する内容
                    ・ ブロック対象となるワードが含まれた内容
                    ・ その他、不適切と判断される内容
                  */}
                {t("thread:aiResponse.invalidContentReasons")}
              </Typography>
            </>;
            break;
          case "server_busy":
            {/*
              t:現在、「{{aiModelName}}」のサーバーが混み合っているため、回答が中止されました。
                しばらくしてから再度リトライしてください。
            */}
            message = <>{t(
                "thread:aiResponse.serverBusy",
                {aiModelName}
            )}</>;
            break;
          case "internal_error":
            {/*
              t:「{{aiModelName}}」の内部でエラーが発生したようです。
                しばらくしてから再度リトライするか、入力内容を調整して投稿することで状況が改善する可能性があります。
            */}
            message = <>{t(
                "thread:aiResponse.internalError",
                {aiModelName}
            )}</>;
            break;
          case "loop_detected":
            {/*
              t:ループが発生している可能性があるため、処理を停止いたしました。
                お手数ですが再度リトライいただくか、入力内容を調整して投稿することで状況が改善する可能性があります。
            */}
            message = <>{t("thread:aiResponse.loopDetected")}</>;
            break;
          case "timeout":
            {/*
              t:「{{aiModelName}}」の回答がタイムアウトしました。
                しばらくしてから再度リトライしてください。
            */}
            message = <>{t(
                "thread:aiResponse.timeout",
                {aiModelName}
            )}</>;
            break;
          case "user_abort":
            // t:生成が中断されました。
            message = <>{t("thread:aiResponse.userAbort")}</>;
            break;
        }

        return <>
          <Typography variant="body1" color="error" gutterBottom  style={{ whiteSpace: 'pre-line' }}>
            {message}
          </Typography>
          { failureCanRetry &&
            <Button variant="outlined" color="primary" size="small"
              onClick={() => retryAIMessage(currentThread.id, node.id, aiMessage.id)}>
              {/* t:リトライ */}
              {t("common:button.retry")}
            </Button>}
        </>
      })()}
    </AIPostWrapper>
  );
}

export default ThreadPostAiMessage;
