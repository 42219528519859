import { useEffect, useState } from 'react';
import { Box, TextField, Typography, FormControl, InputLabel, Select, MenuItem, Divider, List, ListItem, ListItemIcon, ListItemText, Paper, useTheme } from '@mui/material';
import { QpEditStep1Data } from '../qpEditForm.type';
import IconPicker from '../../generic/containers/IconPicker';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store';
import { fetchQpManagement, fetchQuickPromptSetsIfNeeded } from '../../quickPrompt/quickPrompt.slice';
import { ThreadTitle } from '../../thread/presenters/ThreadPresenter';
import { ChevronRight } from '@mui/icons-material';
import DynamicIcon from '../../generic/containers/DynamicIcon';
// import WorkAizerLogo from '@/assets/images/logo/logo_trim.svg';
import { sortQpSetByQpManagementOrder } from '../../quickPrompt/quickPrompt.utils';
import { QpAuthorityType } from '../../quickPrompt/quickPrompt.constant';
import ArrowTooltip from '../../generic/containers/ArrowTooltip';

export const NEW_QP_SET = 'NEW-QP-SET';

interface Step1BasicInfoProps {
  data: QpEditStep1Data,
  onChangeData: (data: QpEditStep1Data) => void;
  onChangeComplete: (completed: boolean) => void;
  isScreenDownMd: boolean;
  isScreenDownSm: boolean;
}

function QpEditStep1BasicInfoContainer({
  data,
  onChangeData,
  onChangeComplete,
  isScreenDownMd,
  isScreenDownSm,
}: Step1BasicInfoProps) {
  const dispatch = useDispatch<AppDispatch>();
  const {
    quickPromptSets,
    qpManagement
  } = useSelector((state: RootState) => state.quickPrompt);
  const theme = useTheme();

  // 下記の条件を全て満たすものを選択可能なセットとする
  //   - isOfficial=False
  //   - hideSetIdsに含まれない
  // またサイドバーの並び順にあわせて並び替える
  const selectableQuickPromptSets = sortQpSetByQpManagementOrder(
    quickPromptSets.filter(set => (
      !set.isOfficial &&
      !qpManagement.hideSetIds.includes(set.id) &&
      set.authorityType != QpAuthorityType.USER
    )),
    qpManagement
  );

  const [selectedSetId, setSelectedSetId] = useState<string | undefined>(data.setId || undefined);
  const [newSetName, setNewSetName] = useState(data.newSetName || '');
  const [name, setName] = useState(data.name || '');
  const [icon, setIcon] = useState(data.icon || '');
  const [description, setDescription] = useState(data.description || '');
  const [shortDescription, setShortDescription] = useState(data.shortDescription || '');

  const isNextButtonEnabled = (
    name.trim().length > 0 &&
    name.trim().length <= 100 &&
    (
      (
        !!selectedSetId &&
        selectedSetId != NEW_QP_SET
      ) ||
      (
        selectedSetId == NEW_QP_SET &&
        newSetName.trim().length > 0
      )
    )
  );

  useEffect(() => {
    // quickPromptSets が未取得の場合に取得する
    if (quickPromptSets.length === 0) {
      dispatch(fetchQuickPromptSetsIfNeeded());
      dispatch(fetchQpManagement());
    }
  }, [dispatch, quickPromptSets]);

  useEffect(() => {
    // 変更内容を入力
    const updatedData = {
      setId: selectedSetId,
      newSetName: newSetName,
      name,
      icon,
      description,
      shortDescription,
    };
    onChangeData(updatedData);

    // 入力完了の判定
    onChangeComplete(isNextButtonEnabled);
  }, [
    selectedSetId,
    newSetName,
    name,
    icon,
    description,
    shortDescription
  ]);

  // プレビュー用の情報
  const selectedSet = selectedSetId ? selectableQuickPromptSets.find(set => set.id === selectedSetId) : null;

  // 選択されたセットIDが有効かどうかを確認
  const isValidSetId = selectedSetId === NEW_QP_SET || selectableQuickPromptSets.some(set => set.id === selectedSetId);

  return (
    <Box
      display="flex"
      flexDirection={isScreenDownMd ? 'column' : 'row'}
      p={2}
      sx={{
        flexGrow: 1,
        overflow: 'auto',
        height: '100%'
      }}
    >
      {/* 入力欄 */}
      <Box flex={1} pr={isScreenDownMd ? 0 : 1}>
        <Typography variant="h6" gutterBottom>
          {
            isScreenDownSm ?
            "1. 基本情報入力" :
            "基本情報"
          }
        </Typography>
        <Box>
          <TextField
            label="名前"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            inputProps={{ maxLength: 50 }}
            fullWidth
            margin="normal"
            placeholder='クイックプロンプトの名前です。(50文字まで)'
            sx={{ '&::placeholder': { fontSize: '0.875rem' } }}
          />

          <FormControl fullWidth margin="normal" required>
            <InputLabel id="quick-prompt-set-select-label">カテゴリ選択</InputLabel>
            <Select
              labelId="quick-prompt-set-select-label"
              id="quick-prompt-set-select"
              value={isValidSetId ? selectedSetId || '' : ''}
              label="カテゴリ選択"
              onChange={(e) => {
                const value = e.target.value || undefined;
                setSelectedSetId(value);
              }}
            >
              {selectableQuickPromptSets.map(set => (
                <MenuItem key={set.id} value={set.id}>
                  {set.name}
                </MenuItem>
              ))}
              <MenuItem key={NEW_QP_SET} value={NEW_QP_SET}>
                <em>新しいカテゴリを作成</em>
              </MenuItem>
            </Select>
          </FormControl>

          {
            selectedSetId === NEW_QP_SET ? <>
              <TextField
                label="新しいカテゴリの名前"
                value={newSetName}
                onChange={(e) => setNewSetName(e.target.value)}
                required
                fullWidth
                margin="normal"
                placeholder='(例) 要約・翻訳'
              />
              <Typography variant="caption" color="textSecondary">
                新しいカテゴリの追加の場合は、カテゴリ名を入力してください。
              </Typography>
            </> :
            (

              <Typography variant="caption" color="textSecondary">
                {/* 公式提供のカテゴリ(<img src={WorkAizerLogo} alt="WorkAizer Logo" width={15} height={15} />がついているもの)は選択できません。 */}
                編集権限がないカテゴリは選択できません。
              </Typography>
            )
          }

          <TextField
            label="説明文"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            inputProps={{ maxLength: 2000 }}
            fullWidth
            multiline
            rows={4}
            margin="normal"
            placeholder={
              `入力フォーム上部に表示する説明文です。\n用途や使い方等をご記載ください。\n(2000文字まで)`
            }
            sx={{ 'textarea::placeholder': { fontSize: '0.875rem' } }}
          />

          <Box margin="normal">
            <Typography variant="caption" color="textSecondary" gutterBottom>
              アイコン選択
            </Typography>
            <IconPicker
              value={icon}
              onChange={(iconName) => setIcon(iconName)}
            />
          </Box>

          <TextField
            label="サイドバーに表示される説明文"
            value={shortDescription}
            onChange={(e) => setShortDescription(e.target.value)}
            inputProps={{ maxLength: 150 }}
            fullWidth
            multiline
            rows={2}
            placeholder='左サイドバーにて、マウスカーソルを上に乗せた時に表示される説明です。(150文字まで)'
            margin="normal"
            sx={{ 'textarea::placeholder': { fontSize: '0.875rem' } }}
          />
        </Box>
      </Box>
      {/* プレビュー */}
      {!isScreenDownMd && (
        <Box flex={1} pl={4} ml={3} borderLeft={`1px solid ${theme.palette.divider}`}>
          <Typography variant="h6" gutterBottom>プレビュー</Typography>
          <Box>
            <Typography variant="subtitle1" color="textSecondary">サイドバーでの表示</Typography>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <List sx={{ display: 'inline-block', bgcolor: 'background.paper' }}>
                <ListItem dense sx={{ pl: 0 }}>
                  <ListItemIcon sx={{ minWidth: 27 }}>
                    <ChevronRight />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      (!selectedSet && !newSetName) ?
                      <i>
                        {
                          selectedSetId == NEW_QP_SET ?
                          '新しいカテゴリ名を入力してください' :
                          'カテゴリを選択してください'
                        }
                      </i> :
                      <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                        {selectedSet ? selectedSet.name : newSetName}
                      </Box>
                    }
                    sx={{ m: 0, '& .MuiListItemText-primary': { fontSize: '0.875rem' } }}
                  />
                </ListItem>
                <ArrowTooltip
                  title={shortDescription}
                  placement="right"
                  disableInteractive
                  open={true}
                  // 時間差なく表示されるようにする (ステップ切替時に違和感あるので)
                  TransitionProps={{ timeout: 0 }}
                  PopperProps={{
                    // モーダルの上に重ならないようにする
                    style: { zIndex: 1 }
                  }}
                >
                  <ListItem dense sx={{ pl: 5 }}>
                    <ListItemIcon sx={{ minWidth: 35 }}>
                      <DynamicIcon iconName={icon} />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ m: 0, flexGrow: 1, '& .MuiListItemText-primary': { fontSize: '0.875rem' } }}
                      primary={
                        name ? name : <i>名前を入力してください</i>
                      }
                    />
                  </ListItem>
                </ArrowTooltip>
              </List>
            </Paper>

            <Typography variant="subtitle1" color="textSecondary">入力フォームの上部</Typography>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <Box>
                {
                  name ?
                  <ThreadTitle variant="h6">{name}</ThreadTitle> :
                  <ThreadTitle variant="body2"><i>名前を入力してください</i></ThreadTitle>
                }


                <Divider sx={{ my: 0.5 }} />
                <Box>
                  <Typography variant="body2" color="textSecondary" py={1}>{description}</Typography>
                </Box>
              </Box>
            </Paper>

          </Box>
        </Box>
      )}
    </Box>
  );
}

export default QpEditStep1BasicInfoContainer;
