import { Box, Button, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { ContentsWrapper, FormArea, LeftSideBarWrapper, MainAreaWrapper, RightSideBarWrapper } from '@/components/ui/StyledComponents';
import { QpEditStep1Data, QpEditStep2Data, QpEditStep3Data, QpEditStep4Data } from '../qpEditForm.type';
import QpEditStep1BasicInfoContainer from '../containers/QpEditStep1BasicInfoContainer';
import QpEditStep2FormBuildContainer from '../containers/QpEditStep2FormBuildContainer';
import QpEditStep3PromptTemplateContainer from '../containers/QpEditStep3PromptTemplateContainer';
import QpEditStep4ConfirmationContainer from '../containers/QpEditStep4ConfirmationContainer';
import { useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import QpEditRightSidebar from '../containers/QpEditRightSidebar';
import { useTheme } from '@mui/material/styles';
import styled from "@emotion/styled";
import theme from "@/theme";

export const CustomMainAreaWrapper = styled(MainAreaWrapper)`
  padding: 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px); // 画面の高さからヘッダーの高さを引く
  overflow-y: auto; // コンテンツが多い場合にスクロールを表示
`;

export const FooterWrapper = styled(FormArea)`
  margin-bottom: 5px;
  padding-right: 32px;
`;

export const CustomLeftSideBarWrapper = styled(LeftSideBarWrapper)`
  ${() => theme.breakpoints.down('md')} {
    width: 185px;
    min-width: 185px;
  }
`;

export const CustomFormArea = styled(FormArea)`
  height: 100%;
  overflowY: auto;
  margin-bottom: 0;
`;

interface QpEditPresenterProps {
  currentStep: number;
  step1Data: QpEditStep1Data;
  step2Data: QpEditStep2Data;
  step3Data: QpEditStep3Data;
  step4Data: QpEditStep4Data;
  canSave: boolean;
  isEditMode: boolean;
  onSave: () => void;
  onChangeData: (step: number, data: QpEditStep1Data | QpEditStep2Data | QpEditStep3Data) => void;
  onStepClick: (clickedStep: number) => void;
  onChangeStepComplete: (step: number, completed: boolean) => void;
  onNextClick: () => void;
  onBackClick: () => void;
  isStepSelectable: (step: number) => boolean;
}

function QpEditPresenter({
  currentStep,
  step1Data,
  step2Data,
  step3Data,
  step4Data,
  canSave,
  isEditMode,
  onSave,
  onChangeData,
  onStepClick,
  onChangeStepComplete,
  onNextClick,
  onBackClick,
  isStepSelectable,
}: QpEditPresenterProps) {
  const theme = useTheme();
  const shouldHideRightSidebar = useMediaQuery('(max-width:1350px)');
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
  const isScreenDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const isScreenDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleRightSidebarToggle = () => {
    setIsRightSidebarOpen(!isRightSidebarOpen);
  };

  const steps = [
    {
      number: 1,
      label: '基本情報入力',
      description: 'クイックプロンプトの基本情報です。プレビューを参考にしながら入力してください。',
    },
    {
      number: 2,
      label: 'フォーム作成',
      description: 'クイックプロンプトのフォームを作成します。項目を追加・編集してください。',
    },
    {
      number: 3,
      label: 'プロンプト作成',
      description: '生成AIに送信するプロンプトの内容を作成します。プロンプト内でフォームの項目を利用できます。',
    },
    {
      number: 4,
      label: '動作確認',
      description: 'クイックプロンプトの動作確認を行います。問題なければ保存してください。',
    },
  ];

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <QpEditStep1BasicInfoContainer
            data={step1Data}
            onChangeData={(data: QpEditStep1Data) => onChangeData(1, data)}
            onChangeComplete={(completed: boolean) => onChangeStepComplete(1, completed)}
            isScreenDownMd={isScreenDownMd}
            isScreenDownSm={isScreenDownSm}
          />
        );
      case 2:
        return (
          <QpEditStep2FormBuildContainer
            data={step2Data}
            step1Data={step1Data}
            onChangeData={(data: QpEditStep2Data) => onChangeData(2, data)}
            onChangeComplete={(completed: boolean) => onChangeStepComplete(2, completed)}
            isScreenDownMd={isScreenDownMd}
            isScreenDownSm={isScreenDownSm}
          />
        );
      case 3:
        return (
          <QpEditStep3PromptTemplateContainer
            data={step3Data}
            properties={step2Data.properties || []}
            onChangeData={(data: QpEditStep3Data) => onChangeData(3, data)}
            onChangeComplete={(completed: boolean) => onChangeStepComplete(3, completed)}
            isScreenDownMd={isScreenDownMd}
            isScreenDownSm={isScreenDownSm}
          />
        );
      case 4:
        return (
          <QpEditStep4ConfirmationContainer
            step4Data={step4Data}
            isEditMode={isEditMode}
            isScreenDownMd={isScreenDownMd}
            isScreenDownSm={isScreenDownSm}
          />
        );
      default:
        return null;
    }
  };

  const canGoBack = currentStep > 1 && isStepSelectable(currentStep-1);
  const canGoNext = currentStep < 4 && isStepSelectable(currentStep+1);

  const handleBackClick = () => {
    if (canGoBack) {
      onBackClick();
    }
  };

  const handleNextClick = () => {
    if (canGoNext) {
      onNextClick();
    }
  };

  return (
    <ContentsWrapper>
      {/* 左サイドバー (ナビゲーション) */}
      { !isScreenDownSm &&
        <CustomLeftSideBarWrapper>
          <List component="nav">
            <ListItem>
              <ListItemText primary={isEditMode ? 'クイックプロンプト編集' : 'クイックプロンプト作成'} />
            </ListItem>
            {steps.map((step) => (
              <Box key={step.number}>
                <ListItemButton
                  onClick={() => {
                    onStepClick(step.number);
                  }}
                  disabled={!isStepSelectable(step.number)}
                  selected={currentStep === step.number}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.action.selected,
                    },
                  }}
                >
                  <ListItemText primary={`${step.number}. ${step.label}`} />
                  {currentStep === step.number && (
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      sx={{
                        mt: 0.5,
                        mb: 1,
                        mx: 1.5,
                      }}
                    >
                      {step.description}
                    </Typography>
                  )}
                </ListItemButton>
              </Box>
            ))}
            { isEditMode &&
              <ListItem>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSave}
                    disabled={!canSave}
                  >
                    保存して完了
                  </Button>
                </Box>
              </ListItem>
            }
          </List>
        </CustomLeftSideBarWrapper>
      }

      {/* メインエリア */}
      <CustomMainAreaWrapper>
        <Box className="a" sx={{
          flexGrow: 1,
          overflow: 'auto',
          height: '100%'
        }}>
          <Box className="b" sx={{ height: '100%', overflowY: 'auto' }}>
            <CustomFormArea>
              {renderStepContent()}
            </CustomFormArea>
          </Box>
        </Box>
        {/* フッター */}
        <Box
          component="footer"
          sx={{
            borderTop: 1,
            borderColor: 'divider',
            p: 2,
          }}
        >
          <FooterWrapper>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <Button
                  onClick={handleBackClick}
                  disabled={!canGoBack}
                >
                  前のステップに戻る
                </Button>
              </Box>

              {
                currentStep == 4 ?
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={onSave}
                  disabled={!canSave}
                  sx={{ minWidth: 120 }}
                >
                  保存して完了
                </Button>:
                <Button
                  variant="contained"
                  onClick={handleNextClick}
                  disabled={!canGoNext}
                  sx={{ minWidth: 120 }}
                >
                  次へ進む
                </Button>
              }
            </Box>
          </FooterWrapper>
        </Box>
      </CustomMainAreaWrapper>

      {/* 右サイドバー */}
      {shouldHideRightSidebar ? (
        <>
          <RightSideBarWrapper
            sx={{
              width: '20px !important',
              minWidth: 'unset !important',
              padding: '0 !important',
              justifyContent: 'center',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
            onClick={handleRightSidebarToggle}
          >
            <IconButton size="small">
              {isRightSidebarOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </RightSideBarWrapper>
          <Drawer
            anchor="right"
            open={isRightSidebarOpen}
            onClose={handleRightSidebarToggle}
            sx={{
              '& .MuiDrawer-paper': {
                width: 275,
                paddingTop: 10,
                paddingLeft: 2,
                backgroundColor: theme.palette.background.default,
              },
            }}
          >
            <QpEditRightSidebar />
          </Drawer>
        </>
      ) : (
        <QpEditRightSidebar />
      )}
    </ContentsWrapper>
  );
}

export default QpEditPresenter;
