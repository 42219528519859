import React, { useEffect, useState } from 'react';
import ThreadPresenter from '../presenters/ThreadPresenter';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearCurrentThread, setCurrentThread } from '../thread.slice';
import { Thread, ThreadCreateType, ThreadWithUsage } from '../thread.type';
import { RootState } from '@/store';
import apiAxios from '@/lib/axios';
import useThreadApi from '../hooks/useThreadApi';
import useLoading from '../../generic/hooks/useLoading';
import { useErrorModal } from '../../generic/hooks/useErrorModal';
import { handleDlpCheck } from '../thread.utils';
import { useConfirmModal } from '../../generic/hooks/useConfirmModal';
import { useThreadAlertModal } from '../hooks/useThreadAlertModal';
import { captureException } from '@sentry/react';
import { createMentionOnlyData, extractMentionIdsFromTiptapData, extractMentionOnlyFromTiptapData, hasMentionInTiptapData, isEmptyWithoutMention } from '@/common/utils/tiptap';
import { FileUpload } from '../../file/file.type';
import { checkUploadFile, checkUploadFileAndModel, FileUploadError, upload } from '../../file/file.utils';
import { getCurrentMembership, getCurrentSubscription } from '../../auth/auth.type';
import { PlanType } from '../../subscription/subscription.constant';
import { useTranslation } from 'react-i18next';

interface ThreadContainerProps {
  threadId?: string;
  isTest?: boolean;
}

const ThreadContainer: React.FC<ThreadContainerProps> = ({
  threadId,
  isTest = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createThread, postMessage, stopGeneration } = useThreadApi();
  const { setErrorModal, setUnknownErrorModal } = useErrorModal();
  const { setConfirmModal } = useConfirmModal();
  const {
    showQuotaExceededModal,
  } = useThreadAlertModal();
  const [ inputMessage, setInputMessage ] = useState<object | undefined>(undefined);
  const [ selectedFile, setSelectedFile ] = useState<File | FileUpload | undefined>(undefined);
  const [ fileUploadProgress, setFileUploadProgress ] = useState<string | undefined>(undefined);

  const { setIsLoading } = useLoading();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const membership = getCurrentMembership(loginUser)
  const currentSub = getCurrentSubscription(loginUser);
  const isStarter = !!(currentSub && currentSub.plan.type == PlanType.STARTER);

  const currentThread = useSelector((state: RootState) => state.thread.currentThread);
  const threadOnGenerating = useSelector((state: RootState) => state.thread.threadOnGenerating);

  // 最新のスレッドを取得する
  useEffect(() => {
    const fetchThread = async () => {
      try {
        const response = await apiAxios.get<ThreadWithUsage>(`/threads/${threadId}`);
        dispatch(setCurrentThread(response.data));
      } catch (error) {
        captureException(error);
        setUnknownErrorModal();
        console.error('Failed to fetch thread:', error);
      }
    };

    if (threadId) {
      fetchThread();
    }
  }, [threadId, dispatch, navigate]);

  // 最新のユーザーメッセージからメンションのみ再現する
  useEffect(() => {
    const restoreMentions = (thread: Thread) => {
      const latestUserMessage = thread.messageNodes
        .filter(node => node.userMessage)
        .map(node => node.userMessage)
        .slice(-1)[0];
      if (latestUserMessage && (
        latestUserMessage.mentions.length > 1 ||
        !latestUserMessage.mentions[0].aiModel.isDefault
      )) {
          const onlyMention = createMentionOnlyData(latestUserMessage.mentions);
          setInputMessage(onlyMention);
      } else {
          resetMessage();
      }
    }

    if (currentThread && currentThread.id === threadId) {
      restoreMentions(currentThread);
    }
  }, [currentThread, threadId]);


  // コンポーネントがアンマウントされる時にclearCurrentThread()を実行
  useEffect(() => {
    return () => {
      if (!isTest) {
        dispatch(clearCurrentThread());
      }
    };
  }, [isTest]);

  const inputMessageChanged = (newValue: object | undefined) => {
    setInputMessage(newValue);
  };

  const resetMessage = () => {
    setInputMessage(undefined);
  }

  const resetMessageButKeepMentions = () => {
    // メンションはそのまま維持する
    if (inputMessage && hasMentionInTiptapData(inputMessage)) {
        const onlyMention = extractMentionOnlyFromTiptapData(inputMessage);
        setInputMessage(onlyMention);
    } else {
      resetMessage();
    }
  }

  const handleSendMessage = async () => {
    // ここでメッセージ送信の処理を実装
    console.debug('Sending message:', inputMessage);
    setIsLoading(true);

    // メッセージもファイルも空の場合は何もしない
    if (inputMessage == undefined || isEmptyWithoutMention(inputMessage)) {
      setIsLoading(false);

      setErrorModal(
        // t:メッセージが空です
        t("thread:emptyMessage.title"),
        // t:メンション以外のメッセージを入力してから投稿してください。
        t("thread:emptyMessage.content")
      )

      return;
    }

    // 該当ファイルをアップロードできるかモデルかチェック
    if (selectedFile) {
      let mentionIds: string[] = [];
      if (inputMessage) {
        mentionIds = extractMentionIdsFromTiptapData(inputMessage);
      }
      const errorMsg = checkUploadFileAndModel(selectedFile, mentionIds, membership)
      if (errorMsg) {
        setIsLoading(false);
        // t:ファイルタイプエラー
        setErrorModal(t("thread:fileTypeError"), errorMsg);
        return;
      }
    }

    // ファイルが選択されている場合はアップロード
    let fileUpload: FileUpload | undefined;
    if (selectedFile && selectedFile instanceof File) {
      try {
        fileUpload = await upload(selectedFile, setFileUploadProgress);
        setFileUploadProgress(undefined);
      } catch (err) {
        setFileUploadProgress(undefined);
        setIsLoading(false);
        captureException(err);
        console.error('File upload failed:', err);
        if (err instanceof FileUploadError) {
          setErrorModal(
            // t:ファイルアップロードエラー
            t("thread:fileUploadError.title"),
            err.message + (
              // t:ブラウザをリロードするか、しばらくしてから再度お試しください。
              err.retryable ? t("thread:fileUploadError.retryMessage") : ""
            )
          );
        } else {
          setUnknownErrorModal();
        }
        return;
      }
    }

    const postMessageOrCreateThread = (skipDlpBlock: boolean = false) => {
      const request = {
        type: ThreadCreateType.NORMAL,
        normalDetail: {
          message: inputMessage,
        },
        skipDlpBlock: skipDlpBlock,
        fileUploadId: fileUpload?.id,
      }

      if (currentThread) {
        postMessage(
          currentThread.id,
          request,
          {
            onMessageNodesAdded: () => {
              resetMessageButKeepMentions();
              setIsLoading(false);
            },
            onBlockedByDlp: (result) => handleDlpCheck(
              t, result, postMessageOrCreateThread, setErrorModal, setConfirmModal
            ),
            onQuotaExceeded: (data) => showQuotaExceededModal(data.quotaCheckResult),
            onMaxDepthOver: (data) => {
              // t:スレッドのメッセージ数が最大値に達しました。
              //   このスレッドにはこれ以上メッセージを追加できません。
              //   （最大メッセージ数： {{maxDepth}}）
              alert(t(
                "thread:maxDepthReached.title",
                { maxDepth: data.maxDepth }
              ));
            },
            onClose: () => {
              setIsLoading(false);
            },
          }
        );
      } else {
        createThread(
          request,
          {
            onThreadCreated: () => {
              setIsLoading(false);
              resetMessageButKeepMentions();
            },
            onBlockedByDlp: (result) => handleDlpCheck(
              t, result, postMessageOrCreateThread, setErrorModal, setConfirmModal
            ),
            onQuotaExceeded: (data) => showQuotaExceededModal(data.quotaCheckResult),
            onClose: () => {
              setIsLoading(false);
            },
          }
        );
      }
    };

    setSelectedFile(undefined);
    postMessageOrCreateThread();
  };

  const handleStopGeneration = () => {
      stopGeneration();
  }

  const onSelectFile = async (file: File | undefined) => {
    console.debug(file)
    const errorMsg = await checkUploadFile(file, isStarter)
    if (errorMsg) {
      // t:ファイル添付エラー
      setErrorModal(t("thread:fileAttachmentError"), errorMsg);
      return;
    }
    setSelectedFile(file);
  }

  const showRecommendQp = (
    !isTest &&
    !threadId &&
    !currentThread &&
    !threadOnGenerating
  );

  return (
    <ThreadPresenter
      inputMessage={inputMessage}
      inputMessageChanged={inputMessageChanged}
      handleSendMessage={handleSendMessage}
      handleStopGeneration={handleStopGeneration}
      onGenerating={threadOnGenerating}
      currentThread={currentThread}
      showRecommendQp={showRecommendQp}
      selectedFile={selectedFile}
      onSelectFile={onSelectFile}
      fileUploadProgress={fileUploadProgress}
    />
  );
};

export default ThreadContainer;
