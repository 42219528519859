// import { QpToggleAdvancedSettingDetail } from "@/components/features/quickPrompt/quickPrompt.type";
// import { ReqQpPropertyEdit } from "../../qpEditForm.type";

// interface Props {
//   property: ReqQpPropertyEdit;
//   detail: QpToggleAdvancedSettingDetail;
//   onChange: (data: QpToggleAdvancedSettingDetail) => void;
// }

function QpToggleAdvanceSettingFields() {

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   onChange({
  //     ...detail,
  //     [name]: value,
  //   });
  // };

  return (
    <>
    </>
  );
}

export default QpToggleAdvanceSettingFields;
