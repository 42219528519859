import { QpChoicePropertyDetailBase } from "@/components/features/quickPrompt/quickPrompt.type";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragHandle, Delete } from "@mui/icons-material";
import { useSortable } from "@dnd-kit/sortable";
import { ReqQpPropertyEdit } from "../../qpEditForm.type";
import { ReactElement } from "react";
import QpRequiredCheckbox from "./QpRequiredCheckbox";

interface Props {
  property: ReqQpPropertyEdit;
  allProperties: ReqQpPropertyEdit[];
  detail: QpChoicePropertyDetailBase;
  onChange: (data: QpChoicePropertyDetailBase) => void;
  otherField?: ReactElement;
  singleDefault?: boolean;
}

function SortableItem({ id, option, idx, onChange, onDelete }: any) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      display="flex"
      alignItems="flex-start"
      p={1}
      borderBottom="1px solid #ccc"
    >
      <Box {...attributes} {...listeners} sx={{ cursor: "grab", mr: 2, mt: 3 }}>
        <DragHandle />
      </Box>
      <Checkbox
        checked={option.isDefault || false}
        onChange={(e) => onChange(idx, "isDefault", e.target.checked)}
        sx={{ mt: 2 }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
          <TextField
            label="名前"
            value={option.label || ""}
            onChange={(e) => onChange(idx, "label", e.target.value)}
            margin="normal"
            fullWidth
            required
          />
          <TextField
            label="ヘルプテキスト"
            value={option.helpText || ""}
            onChange={(e) => onChange(idx, "helpText", e.target.value)}
            margin="normal"
            fullWidth
          />
        </Box>
        <TextField
          label="選択時のプロンプト"
          value={option.prompt || ""}
          onChange={(e) => onChange(idx, "prompt", e.target.value)}
          margin="normal"
          multiline
          rows={2}
          fullWidth
          placeholder="プロンプト未入力時は選択肢の名前がそのままプロンプトとして使われます。"
        />
      </Box>
      <IconButton onClick={() => onDelete(idx)} sx={{ mt: 2 }}>
        <Delete />
      </IconButton>
    </Box>
  );
}

function QpChoicePropertySettingFields({
  property,
  allProperties,
  detail,
  onChange,
  otherField,
  singleDefault = true,
}: Props) {
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 5 } }));

  const handleOptionChange = (idx: number, field: string, value: any) => {
    const newOptions = [...detail.options];
    newOptions[idx] = { ...newOptions[idx], [field]: value };

    // labelを変更したときにvalueも同期
    if (field === 'label') {
      newOptions[idx].value = value;
    }

    // singleDefaultがtrueの場合は、他の選択肢のデフォルト設定をリセット
    if (field === 'isDefault' && value === true && singleDefault) {
      for (let i = 0; i < newOptions.length; i++) {
        if (i !== idx) {
          newOptions[i].isDefault = false;
        }
      }
    }

    onChange({ ...detail, options: newOptions });
  };

  const handleAddOption = () => {
    const newOptions = [
      ...detail.options,
      {
        label: "",
        value: "",
        isDefault: false,
        helpText: "",
      }
    ];
    onChange({ ...detail, options: newOptions });
  };

  const handleRemoveOption = (idx: number) => {
    const newOptions = detail.options.filter((_, i) => i !== idx);
    onChange({ ...detail, options: newOptions });
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (!over) return; // ドロップ先が存在しない場合は処理しない

    const oldIndex = parseInt(active.id, 10);
    const newIndex = parseInt(over.id, 10);

    if (oldIndex !== newIndex) {
      const reorderedOptions = arrayMove(detail.options, oldIndex, newIndex);
      onChange({ ...detail, options: reorderedOptions });
    }
  };

  return (
    <Box>
      <QpRequiredCheckbox
        property={property}
        allProperties={allProperties}
        checked={detail.isRequired || false}
        onChange={(e) => onChange({ ...detail, isRequired: e.target.checked })}
      />

      {otherField}

      <TextField
        label="ヘルプテキスト"
        name="helpText"
        value={detail.helpText || ''}
        onChange={(e) => onChange({ ...detail, helpText: e.target.value })}
        fullWidth
        margin="normal"
      />

      <Typography mt={3} variant="subtitle1">選択肢一覧</Typography>
      <Typography variant="caption">チェックを入れた選択肢がデフォルト値になります</Typography>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        {/* インデックスを文字列として渡す */}
        <SortableContext items={detail.options.map((_, i) => i.toString())} strategy={verticalListSortingStrategy}>
          {detail.options.map((option, idx) => (
            <SortableItem
              key={idx}
              id={idx.toString()} // idを文字列として扱う
              option={option}
              idx={idx}
              onChange={handleOptionChange}
              onDelete={handleRemoveOption}
            />
          ))}
        </SortableContext>
      </DndContext>
      <Button onClick={handleAddOption} sx={{ mt: 2 }}>
        選択肢を追加
      </Button>
    </Box>
  );
}

export default QpChoicePropertySettingFields;
