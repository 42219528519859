import { QpSelectAiModelsDetail } from "@/components/features/quickPrompt/quickPrompt.type";
import { SelectAiModelCodesPresenter } from "@/components/features/quickPrompt/components/SelectAiModelCodes";
import { ReqQpPropertyEdit } from "../../qpEditForm.type";
import { TextField } from "@mui/material";

interface Props {
  property: ReqQpPropertyEdit,
  allProperties: ReqQpPropertyEdit[];
  detail: QpSelectAiModelsDetail;
  onChange: (data: QpSelectAiModelsDetail) => void;
}

function QpSelectAiModelsSettingFields({
  property,
  detail,
  onChange,
}: Props) {

  return (
    <>
      <SelectAiModelCodesPresenter
        id={property.id}
        label="初期値"
        onChange={(codes: string[]) => {
          onChange({
            ...detail,
            defaultModels: codes,
          });
        }}
        value={detail.defaultModels || []}
        fullWidth
      />
      <TextField
        label="ヘルプテキスト"
        name="helpText"
        value={detail.helpText || ''}
        onChange={(e) => onChange({
          ...detail,
          helpText: e.target.value,
        })}
        fullWidth
        margin="normal"
      />
    </>
  );
}

export default QpSelectAiModelsSettingFields;
