// シンプルな完全一致マップ（%sなど可変要素を含まないパターン）
export const errorTranslationMap: Record<string, string> = {
  "Unexpected end of template": "テンプレートが予期しない箇所で終了しています。",
  "unexpected end of template": "テンプレートが予期しない箇所で終了しています。",
  "unexpected 'end of template'": "テンプレートが予期しない箇所で終了しています。",
  "tag name expected": "式がない、または不正な式です。",
  "expected 'end of print statement'": "'{{' に対応する '}}' が存在しません。",
  "expected token 'end of print statement'": "'{{' に対応する '}}' が存在しません。",
  "expected 'end of statement block'": "'{%' に対応する '%}' が存在しません。",
  "expected token 'end of statement block'": "'{%' に対応する '%}' が存在しません。",
  ",": "\n",
  ".": "\n",
};

// 正規表現を用いたパターンマッチ用のテーブル
// key: 正規表現パターン（英語）
// value: 対応する日本語メッセージ（$1, $2などでキャプチャした文字を利用可）
export const errorRegexPatterns: Array<{pattern: RegExp; replace: string}> = [
  {
    pattern: /got '(.+?)'/,
    replace: "(現状は '$1' になっています)"
  },
  {
    pattern: /Encountered unknown tag '(.+?)'/,
    replace: "不明な式 '$1' が使用されています。"
  },
  {
    pattern: /Jinja was looking for the following tags: '(.+?)'\. The innermost block that needs to be closed is '(.+?)'\./,
    replace: "'$2' ブロックを閉じるために、'$1' のいずれかの式が必要です。"
  },
  {
    pattern: /expected token '(.+?)'/,
    replace: "'$1' が必要です。"
  },
  {
    pattern: /unexpected '(.+?)'/,
    replace: "予期しない '$1' が見つかりました。"
  },
  {
    pattern: /unexpected token '(.+?)'/,
    replace: "予期しない '$1' が見つかりました。"
  },
];
