import { RootState } from "@/store";
import { Avatar, Box, Checkbox, Chip, InputLabel, MenuItem, Select, FormHelperText } from "@mui/material";
import { WidgetProps } from "@rjsf/utils";
import { useSelector } from "react-redux";
import { getUsableAiModelConsts } from "../../auth/auth.type";
import { AIModelConsts } from "../../aiModel/aiModel.constant";
import AIModelListItem from "../../aiModel/components/AIModelListItem";
import { useTranslation } from "react-i18next";

const SelectAiModelCodes = (props: WidgetProps) => {
  return <SelectAiModelCodesPresenter {...props}/>
}

interface SelectAiModelCodesPresenterProps {
  value: string[];
  onChange?: (value: string[]) => void;
  required?: boolean;
  rawErrors?: string[];
  id: string;
  placeholder?: string;
  label: string;
  fullWidth?: boolean;
  uiSchema?: any;
}

export const SelectAiModelCodesPresenter = ({
  value,
  onChange = () => {},
  required = false,
  rawErrors = [],
  id,
  placeholder,
  label,
  fullWidth = false,
  uiSchema = {},
}: SelectAiModelCodesPresenterProps) => {
  const { t } = useTranslation()

  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const usableAiModelConsts = getUsableAiModelConsts(loginUser)

  // 全てのAIモデルが同じタグを持っているか
  const isAllAiModelHasSameTags = usableAiModelConsts.length > 0 &&
    usableAiModelConsts.every(model =>
      model.tagsT(t).length === usableAiModelConsts[0].tagsT(t).length &&
      model.tagsT(t).every(tag => usableAiModelConsts[0].tagsT(t).includes(tag))
    );

  const help = uiSchema?.help || '';

  return (
    <>
      <InputLabel id="select-ai-model-codes">{label}</InputLabel>
      <Select
        fullWidth={fullWidth}
        label={label}
        labelId="select-ai-model-codes"
        id={id}
        multiple
        value={value}
        required={required}
        onChange={(event) => onChange(
          Array.isArray(event.target.value) ?
          event.target.value : [event.target.value]
        )}
        placeholder={placeholder}
        error={rawErrors ? rawErrors.length > 0 : false}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value: string) => {
              const aiModel = AIModelConsts.find((item) => item.id == value)
              return <Chip
                key={value}
                label={aiModel?.name}
                avatar={<Avatar alt={aiModel?.name} src={aiModel?.avatar} />}
                variant="outlined"
              />
            })}
          </Box>
        )}
      >
        {usableAiModelConsts.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            <Checkbox checked={value.indexOf(item.id) > -1} />
            <AIModelListItem
              aiModel={item}
              isAllAiModelHasSameTags={isAllAiModelHasSameTags}
            />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{help}</FormHelperText>
    </>
  );
};

export default SelectAiModelCodes;
