import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";
import AuthGuard from "./components/features/auth/components/AuthGuard";
import Header from "./components/features/header/components/Header";
import { APIUnauthorizedHandler } from "./components/features/auth/components/APIUnauthorizedHandler";
import LoginPage from "./components/pages/login";
import ErrorPage from "./components/pages/error";
import TeamMembershipGuard from "./components/features/team/components/TeamMembershipGuard";
import TeamsCreatePage from "./components/pages/teams/teams-create";
import TeamsCompletedPage from "./components/pages/teams/teams-completed";
import TopPage from "./components/pages";
import ThreadsLayout from "./components/features/thread/components/ThreadsLayout";
import ThreadsIndexPage from "./components/pages/threads/threads-index";
import ThreadsShowPage from "./components/pages/threads/threads-show";
import TeamAdminGuard from "./components/features/team/components/TeamAdminGuard";
import AdminLayout from "./components/features/admin/components/AdminLayout";
import AdminDlpExpressionConfigsPage from "./components/pages/admin/dlp/expression-configs";
import AdminDlpInfoTypeConfigsPage from "./components/pages/admin/dlp/info-type-configs";
import AdminMembershipsPage from "./components/pages/admin/memberships";
import TeamsSelectPage from "./components/pages/teams/teams-select";
import TeamsNotJoinedPage from "./components/pages/teams/teams-not-joined";
import TeamsJoinSuccessPage from "./components/pages/teams/teams-join-success";
import TeamsJoinPage from "./components/pages/teams/teams-join";
import AdminIndexPage from "./components/pages/admin";
import DebugRouting from "./components/pages/debug/routing";
import { useState } from "react";
import SupervisorGuard from "./components/features/supervisor/base/components/SupervisorGuard";
import SupervisorLayout from "./components/features/supervisor/base/components/SupervisorLayout";
import SupervisorTeamsIndexPage from "./components/pages/supervisor/teams/teams-index";
import SupervisorHeader from "./components/features/supervisor/base/components/SupervisorHeader";
import SvTeamLayoutContainer from "./components/features/supervisor/teams/containers/SvTeamLayoutContainer";
import SupervisorTeamsSummaryPage from "./components/pages/supervisor/teams/teams-summary";
import SupervisorTeamsSubscriptionsIndexPage from "./components/pages/supervisor/teams/subscriptions/subscriptions-index";
import TeamSuperAdminGuard from "./components/features/team/components/TeamSuperAdminGuard";
import AdminMembershipGroupsPage from "./components/pages/admin/membership-groups";
import AdminUsageLatestStatisticPage from "./components/pages/admin/usage/latest-statistic";
import SupervisorLatestUsageStatisticPage from "./components/pages/supervisor/teams/latest-usage-statistics";
import AdminVaultUsagesPage from "./components/pages/admin/vault/usages";
import AdminVaultMessagesPage from "./components/pages/admin/vault/messages";
import AdminSubscriptionIndexPage from "./components/pages/admin/subscription";
import AdminSubscriptionUpgradeContactPage from "./components/pages/admin/subscription/upgrade-contact";
import SupervisorNewsIndexPage from "./components/pages/supervisor/news";
import SupervisorNewsNewPage from "./components/pages/supervisor/news/new";
import SupervisorNewsEditPage from "./components/pages/supervisor/news/edit";
import NewsIndexPage from "./components/pages/news";
import NewsShowPage from "./components/pages/news/show";
import QuickPromptsThreadsNewPage from "./components/pages/quick-prompts/threads/new";
import SupervisorTeamsQuickPromptsBookmarksIndexPage from "./components/pages/supervisor/teams/quick-prompts/bookmarks";
import AdminUsageReportsPage from "./components/pages/admin/usage/reports";
import SupervisorLatestUsageReportPage from "./components/pages/supervisor/teams/usage-reports";
import SupervisorTeamLogoCustomizationPage from "./components/pages/supervisor/teams/logo-customization";
import AdminVaultFilesPage from "./components/pages/admin/vault/files";
import AdminTeamIndexPage from "./components/pages/admin/team/index";
import AdminTeamConfigPage from "./components/pages/admin/team/config";
import SettingsProfilePage from "./components/pages/settings/profile";
import SettingLayout from "./components/features/setting/components/SettingLayout";
import SettingsLocalePage from "./components/pages/settings/locale";
import QuickPromptsNewPage from "./components/pages/quick-prompts/new";
import QuickPromptsEditPage from "./components/pages/quick-prompts/edit";

const ENABLE_DEBUG_PAGES = import.meta.env.VITE_ENABLE_DEBUG_PAGES === "true";

// ルーティング
const Routing = () => {
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(false);

  const toggleLeftSidebar = () => {
    setIsLeftSidebarOpen(!isLeftSidebarOpen);
  };

  const routing = (
    <>
      {/* 運用者画面画面 */}
      <Route path="/supervisor" element={<SupervisorHeader />}>
        <Route element={<AuthGuard />}>
          <Route element={<APIUnauthorizedHandler />}>
            <Route element={<SupervisorGuard />}>
              <Route element={<SupervisorLayout />}>
                <Route path="" element={<Navigate to="teams" replace />} />
                {/* チーム管理 */}
                <Route path="teams" element={<SupervisorTeamsIndexPage />} />

                {/* お知らせ管理 */}
                <Route path="news" element={<SupervisorNewsIndexPage />} />
                <Route path="news/new" element={<SupervisorNewsNewPage />} />
                <Route path="news/:id/edit" element={<SupervisorNewsEditPage />} />
              </Route>

              {/* チーム詳細 */}
              <Route path="teams/:teamId" element={<SvTeamLayoutContainer />}>
                <Route index element={<Navigate to="summary" replace />} />
                {/* サマリー */}
                <Route path="summary" element={<SupervisorTeamsSummaryPage />} />
                {/* 契約管理 */}
                <Route path="subscriptions" element={<SupervisorTeamsSubscriptionsIndexPage />} />
                {/* 最新の利用状況 */}
                <Route path="latest-usage-statistics" element={<SupervisorLatestUsageStatisticPage />} />
                {/* 利用状況レポート */}
                <Route path="usage-reports" element={<SupervisorLatestUsageReportPage />} />
                {/* QPお気に入り管理 */}
                <Route path="quick-prompts/bookmarks" element={<SupervisorTeamsQuickPromptsBookmarksIndexPage />} />
                {/* ロゴカスタマイズ */}
                <Route path="logo-customization" element={<SupervisorTeamLogoCustomizationPage />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      {/* 通常画面 */}
      <Route element={<Header onMenuButtonClick={toggleLeftSidebar} />}>
        {/* ログインページ */}
        <Route path="/login" element={<LoginPage />} />

        {/* 検証用ページ */}
        { ENABLE_DEBUG_PAGES && DebugRouting() }

        {/* 認証が必要なページ。 未ログインの状態でアクセスしたらLoginページへ */}
        <Route element={<AuthGuard />}>
          {/* APIで認証エラーが起きた場合のハンドリング */}
          <Route element={<APIUnauthorizedHandler />}>

            {/* チーム作成 */}
            <Route path="/teams/create" element={<TeamsCreatePage />} />
            {/* チーム選択 */}
            <Route path="/teams/select" element={<TeamsSelectPage />} />
            {/* 参加チームがないこと表示 */}
            <Route path="/teams/not-joined" element={<TeamsNotJoinedPage />} />

            {/* チーム参加 */}
            <Route path="/teams/:teamId/join/:inviteCode" element={<TeamsJoinPage />} />
            <Route path="/teams/join/success" element={<TeamsJoinSuccessPage />} />

            {/* 個人設定 */}
            <Route element={<SettingLayout isLeftSidebarOpen={isLeftSidebarOpen} onCloseSidebar={toggleLeftSidebar} />}>
              <Route path="/setting" element={<Navigate to="/setting/profile" />} />
              <Route path="/setting/profile" element={<SettingsProfilePage />} />
              <Route path="/setting/locale" element={<SettingsLocalePage />} />
            </Route>

            {/* チームに所属しているかチェックする新しいガードを追加 */}
            <Route element={<TeamMembershipGuard />}>

              {/* チーム作成完了 */}
              <Route path="/teams/completed" element={<TeamsCompletedPage />} />

              {/* スレッド画面 */}
              <Route element={<ThreadsLayout isLeftSidebarOpen={isLeftSidebarOpen} onCloseSidebar={toggleLeftSidebar} />}>
                <Route path="/" element={<TopPage />} />
                <Route path="/threads" element={<ThreadsIndexPage />} />
                <Route path="/threads/:threadId" element={<ThreadsShowPage />} />
                <Route path="/quick-prompts/:quickPromptId/threads/new" element={<QuickPromptsThreadsNewPage />} />
              </Route>

              {/* お知らせ画面 */}
              <Route path="/news" element={<NewsIndexPage />} />
              <Route path="/news/:id" element={<NewsShowPage />} />

              {/* クイックプロンプト作成 */}
              <Route path="/quick-prompts/new" element={<QuickPromptsNewPage />} />
              <Route path="/quick-prompts/:quickPromptId/edit" element={<QuickPromptsEditPage />} />

              {/* 管理者画面 */}
              <Route element={<TeamAdminGuard />}>
                <Route element={<AdminLayout isLeftSidebarOpen={isLeftSidebarOpen} onCloseSidebar={toggleLeftSidebar} />}>
                  <Route path="/admin" element={<AdminIndexPage />} />

                  {/* チーム */}
                  <Route element={<TeamSuperAdminGuard />}>
                    <Route path="/admin/team" element={<AdminTeamIndexPage />} />
                    <Route path="/admin/team/config" element={<AdminTeamConfigPage />} />
                  </Route>

                  {/* メンバー管理 */}
                  <Route path="/admin/memberships" element={<AdminMembershipsPage />} />
                  {/* グループ管理 */}
                  <Route path="/admin/membership-groups" element={<AdminMembershipGroupsPage />} />

                  {/* DLP */}
                  <Route path="/admin/dlp/expression-configs" element={<AdminDlpExpressionConfigsPage />} />
                  <Route path="/admin/dlp/info-type-configs" element={<AdminDlpInfoTypeConfigsPage />} />

                  {/* 利用状況 */}
                  <Route path="/admin/usage/latest-statistics" element={<AdminUsageLatestStatisticPage />} />
                  <Route path="/admin/usage/reports" element={<AdminUsageReportsPage />} />

                  <Route element={<TeamSuperAdminGuard />}>
                    {/* 契約 */}
                    <Route path="/admin/subscription" element={<AdminSubscriptionIndexPage />} />
                    <Route path="/admin/subscription/upgrade-contact" element={<AdminSubscriptionUpgradeContactPage />} />
                    {/* Vault */}
                    <Route path="/admin/vault/usages" element={<AdminVaultUsagesPage />} />
                    <Route path="/admin/vault/messages" element={<AdminVaultMessagesPage />} />
                    <Route path="/admin/vault/files" element={<AdminVaultFilesPage />} />
                  </Route>


                </Route>
              </Route>

            </Route> {/* TeamMembershipGuard */}
          </Route> {/* APIUnauthorizedHandler */}
        </Route> {/* AuthGuard */}

        {/* エラーページ */}
        <Route path="/*" element={<ErrorPage />} />
      </Route>
    </>
  );

  const router = createBrowserRouter(
    createRoutesFromElements(routing)
  );

  return <RouterProvider router={router} />;
}

export default Routing;
