// src/quickPrompt/components/QpSetEditModal.tsx

import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, TextField
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store';
import { useToast } from '../../generic/hooks/useToast';
import { useTranslation } from 'react-i18next';
import { updateQuickPromptSet } from '../../quickPrompt/quickPrompt.slice';
import { QuickPromptSetWithQpList } from '../../quickPrompt/quickPrompt.type';

interface QpSetEditModalProps {
  open: boolean;
  onClose: () => void;
  quickPromptSet: QuickPromptSetWithQpList;
}

const QpSetEditModal: React.FC<QpSetEditModalProps> = ({ open, onClose, quickPromptSet }) => {
  const [name, setName] = useState(quickPromptSet.name);
  const dispatch = useDispatch<AppDispatch>();
  const { showToast } = useToast();
  const { t } = useTranslation();

  const handleSave = async () => {
    await dispatch(updateQuickPromptSet({ id: quickPromptSet.id, name })).unwrap();
    showToast(t('quickPromptManagement:qpSetEditModal.messages.updateSuccess'), 'success');
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {/* t: グループ編集 */}
      <DialogTitle>{t('quickPromptManagement:qpSetEditModal.title')}</DialogTitle>
      <DialogContent>
        <TextField
          id={`name-${quickPromptSet.id}`}
          label={t('quickPromptManagement:qpSetEditModal.fields.name')}
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="dense"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>
          {/* t: キャンセル */}
          {t('common:button.cancel')}
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          {/* t: 保存 */}
          {t('common:button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QpSetEditModal;
