import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { ReqQpPropertyEdit } from "../../qpEditForm.type";
import { isAdvanceSettingProperty } from "../../qpEditForm.utils";

interface RequiredCheckboxProps {
  property: ReqQpPropertyEdit;
  allProperties: ReqQpPropertyEdit[];
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string
  name?: string
}

function QpRequiredCheckbox({
  property,
  allProperties,
  checked,
  onChange,
  label = "必須",
  name = "isRequired"
}: RequiredCheckboxProps) {

  const isAdvanceSetting = isAdvanceSettingProperty(
    property,
    allProperties
  );

  return (
    <Tooltip title={isAdvanceSetting ? "詳細設定の場合は必須には出来ません" : ""}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            name={name}
            disabled={isAdvanceSetting}
          />
        }
        label={label}
      />
    </Tooltip>
  );
}

export default QpRequiredCheckbox;
